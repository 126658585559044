import React, { useState } from 'react'
import { Translate } from 'react-auto-translate'
import Select from 'react-select'

const SimpleRadioDropdown = (props) => {

    // ** States
    const [otherField, setOtherField] = useState(typeof props.completeQuestionData['value'] === "object" && props.completeQuestionData['value']['value'] === "Other")

    const handleChange = (value) => {
        if (value.value === "Other") {
            setOtherField(true)
        } else {
            setOtherField(false)
        }
        props.completeQuestionData['otherValue'] = ''
        props.completeQuestionData['value'] = value
        props?.validateData()
    }

    const handleOtherField = (event) => {
        if ((event.target.value).length) {
            props.completeQuestionData['otherValue'] = `${event.target.value}`
        } else {
            props.completeQuestionData['otherValue'] = ''
        }
        props?.validateData()
    }

    return (
        <div className={`single-group-child single-element} ${props?.item['isError'] ? 'error-in-question' : ''}`}>
            <strong className='strongclass'><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='mandatory'>*</span> : null}</strong>
            <div className='ui form'>
            <div className='field'>
                {/* options={states} */}
                <Select options={props?.item?.options} onChange={(item) => handleChange(item)} placeholder="Select" className='react-select' value={{label: props?.completeQuestionData?.value?.label, value: props?.completeQuestionData?.value?.value, isFixed: true}} classNamePrefix='select'/>
                {/* onChange={(item) => handleSelectState(item)} */}
                {otherField ? <input id={'other'}
                name={props?.item?.groupName}
                className='w-100 mb-2 mt-2'
                placeholder='Please specify'
                value={props.completeQuestionData['otherValue']}
                onChange={(event) => handleOtherField(event)}/> : null
                }
            </div>
        </div> 
        {props?.item['isError'] ? <p className='error-message-container'><Translate>{props?.item['errorMessage']}</Translate></p> : null}
        </div>
    )
}

export default SimpleRadioDropdown