import React, { useEffect, useState } from 'react'
import './style.css'
import SoulfulLogo from '../../soulful_logo.png'
import { Translate } from 'react-auto-translate'
import Switch from "react-switch";

const Header = () => {

    const [checked, setChecked] = useState(false)
    useEffect(() => {
        const selectedLanguage = localStorage.getItem('newFormToLanguage') !== null ? localStorage.getItem('newFormToLanguage') : 'es'
        if (selectedLanguage === "en") {
          setChecked(true)
        } else {
          setChecked(false)
        }
      }, [])
    
      const handleChangeLanguage = (checked) => {
        setChecked(checked)
        const selectedLanguage = localStorage.getItem('newFormToLanguage') !== null ? localStorage.getItem('newFormToLanguage') : 'es'
        let toLanguage = ''
        let fromLanguage = ''
        if (selectedLanguage === "en") {
          toLanguage = 'es'
          fromLanguage = 'en'
        } else {
          fromLanguage = 'es'
          toLanguage = 'en'
        }
        localStorage.setItem('newFormToLanguage', toLanguage)
        localStorage.setItem('newFormFromLanguage', toLanguage)
        window.location.reload()
      }

    return (
        <div className='header-container'>
            <div className='header-inner-container justify-content-between'>
                <div className='header-inner-container'>
                    <img src={SoulfulLogo} alt="soulfulimg" className='soulfulimg' height={160} width={160}/>
                    <div className='mx-3'>
                        <h1><Translate>Soulful Synergy</Translate></h1>
                        <h3 style={{marginLeft: '10px'}}><Translate>Transportation Electrification</Translate></h3>
                        <h5 style={{marginLeft: "12px"}}><Translate>This training is FREE for eligible New York State workers</Translate></h5>
                    </div>
                </div>
                <p className='text-end mt-1 my-1'>
                    <Switch className='language-switch' onChange={handleChangeLanguage} width={100} checked={checked} uncheckedIcon={<span>Spanish</span>} checkedIcon={<span>English</span>}/>
                </p>
            </div>
        </div>
    )
}

export default Header