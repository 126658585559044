import React, { useEffect, useState } from 'react'
import { Translate } from 'react-auto-translate';
import { Badge } from 'reactstrap';
// import './index.css'

const SimpleRadioWithOther = (props) => {
    // console.log(props)

    const [editable, setEditable] = useState(false)
    const [otherField, setOtherField] = useState(false)
    const [hideCancel, setHideCancel] = useState(false)
    const [valuesLength, setValuesLength] = useState(null)
    const [disabled, setDisabled] = useState(props?.activeStep === 5 ? true : false)

    useEffect(() => {
      if (props?.completeQuestionData?.value) {
        setValuesLength((props?.completeQuestionData?.value).length)
      }
    }, [])
    
    useEffect(() => {
      if (valuesLength) {
        if ((valuesLength > (props?.completeQuestionData?.value).length) || (valuesLength < (props?.completeQuestionData?.value).length)) {
          setHideCancel(true)
        }
        console.log("value:------------------", valuesLength, (props?.completeQuestionData?.value).length, (valuesLength > (props?.completeQuestionData?.value).length) || (valuesLength < (props?.completeQuestionData?.value).length));
      }
    }, [props?.completeQuestionData?.value])
  
    const handleClickCancel = () => {
      setEditable(false)
      setHideCancel(false)
      setDisabled(true)
    }

    const handleChange = (event, option) => {
        console.log("option1: ", option, props.item.dataSet.errorMessage, props?.item?.dataSet?.isRequired, props.item.dataSet['isError']);
        if (option === "Other" || option === "I don’t know") {
            if (props?.item?.dataSet?.isRequired) {
                props.item.dataSet['isError'] = true
                props.item.dataSet['errorMessage'] = 'Required'
            }
            props.item['value'] = 'Other'
            setOtherField(!otherField)
            props?.validateData()
        } else {
            props.item['value'] = event.target.value
            setOtherField(false)
            props.item.dataSet['isError'] = false
            props.item.dataSet['errorMessage'] = ''
            props?.validateData()
        }
    }

    const handleOtherField = (event) => {
        props.item['value'] = `Other: ${event.target.value}`
        props?.validateData()
    }

    return (
        <div className={`single-group-child position-relative ${props?.formType} special-component ${props?.btnDisabled ? "disabled" : ''} ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
            {/* <strong className={`question-element ${props?.formType} special-component`}><Translate>{props?.item?.dataSet?.question}</Translate>{props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}{props?.activeStep === 5 ? <Badge className='mx-2 round' color={editable ? 'success' : 'warning'}  role='pill' style={{cursor: 'pointer', color: editable ? 'white' : 'black'}} onClick={() => {setEditable(!editable); setHideCancel(editable)}}>{editable ? <Translate>Save</Translate> : <Translate>Edit</Translate>}</Badge>: null}{props?.activeStep === 5 && (editable && hideCancel === false)? <Badge className='mx-2 round' color={'secondary'}  role='pill' style={{cursor: 'pointer', color: 'white'}} onClick={() => handleClickCancel()}>{<Translate>Cancel</Translate>}</Badge>: null}</strong>
             */}
            <strong className={`question-element ${props?.formType} special-component`}><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='required'>*</span> : null}{props?.activeStep === 5 ? <Badge className='mx-2 round' color={editable ? 'success' : 'warning'}  role='pill' style={{cursor: 'pointer', color: editable ? 'white' : 'black'}} onClick={() => {setEditable(!editable); setHideCancel(editable); setDisabled(editable)}}>{editable ? <Translate>Save</Translate> : <Translate>Edit</Translate>}</Badge>: null}{props?.activeStep === 5 && (editable && hideCancel === false)? <Badge className='mx-2 round' color={'secondary'}  role='pill' style={{cursor: 'pointer', color: 'white'}} onClick={() => handleClickCancel()}>{<Translate>Cancel</Translate>}</Badge>: null}</strong>
            {(props?.item?.dataSet?.options).map((option, i) => {
                return (
                    <div className={`option-container check-option${editable ? '1' : ''} ${(props?.item?.value !== null ? (props?.item?.value).includes(option || 'Other') : false) ? 'show' : props?.activeStep === 5 ? 'hide' : 'show'}`} key={i}>
                        <input type="radio" id={(option)}
                         name={props?.item?.dataSet?.groupName}
                         defaultValue={option} 
                         disabled={props?.btnDisabled || disabled}
                         checked={props?.item?.value !== null ? (props?.item?.value).includes(option || 'Other') : false}
                         onChange={(event) => {handleChange(event, option)}}>

                         </input>
                        <span className={`${props?.formType} option-label`}><Translate>{option}</Translate></span>
                    </div>
                )
            })}

            {otherField ? <input id={'other'}
            name={props?.item?.groupName}
            className='w-100 mb-2'
            // value={option} 
            onChange={(event) => handleOtherField(event)}/> : null
            }

            {props?.item?.dataSet['isError'] ? <p className='error-message-container'><Translate>{props?.item?.dataSet['errorMessage']}</Translate></p> : null}
        </div>
    )
}

export default SimpleRadioWithOther

// We want one component for one question type. But in your case you make one one component for input, radio, checkbox and then using all these for making one question component