import React from 'react'
import './style.css'
import { Translate } from 'react-auto-translate';

import check from './../../assets/check.png'
import arrow from './../../assets/arrow.png' 

import step1 from './../../assets/1.png' 
import step11 from './../../assets/11.png' 

import step2 from './../../assets/2.png'
import step22 from './../../assets/22.png' 

import step3 from './../../assets/3.png' 
import step33 from './../../assets/33.png' 

import step4 from './../../assets/4.png'
import step44 from './../../assets/44.png' 

import step5 from './../../assets/5.png' 
import step55 from './../../assets/55.png' 

import step6 from './../../assets/6.png'
import step66 from './../../assets/66.png' 

const Stepper = ({currentStep}) => {
    const activeStep = currentStep;
    console.log("step:---------------", activeStep);
    const data = [
        {
            image: activeStep === 0 ? step11 : step1,
            name: 'Personal Information',
            check: activeStep === 0 || (0 < activeStep) ? 1 : 0,
        },
        {
            image: activeStep === 1 ? step22 : step2,
            name: 'Course Selection',
            check: activeStep === 1 || (1 < activeStep) ? 1 : 0,
        },
        {
            image: activeStep === 2  ? step33 : step3,
            name: 'Experience',
            check: activeStep === 2 || (2 < activeStep) ? 1 : 0,
        },
        {
            image: activeStep === 3 ? step44 : step4,
            name: 'Demographics',
            check: activeStep === 3 || (3 < activeStep) ? 1 : 0,
        },
        {
            image: activeStep === 4 ? step55 : step5,
            name: 'Feedback',
            check: activeStep === 4 || (4 < activeStep) ? 1 : 0,
        },
        {
            image: activeStep === 5 ? step66 : step6,
            name: 'Review & Submit',
            check: activeStep === 5 || (5 < activeStep) ? 1 : 0,
        }
    ]

    return (
        <div className='stepper-container'>
            <div className='inner-stepper-container'>
                <div className='stepper-item-main-container'>
                    {data.map((item, i) => {
                        return (
                            <div className='stepper-item-container'>
                                <div className='stepper-item-image-container'>
                                    <img className={`step-image`} src={`${item.image}`} alt='step-image'></img>
                                    {item.check && activeStep !== i ? <img className='step-completed' src={check} alt='step-completed'></img> : null}
                                    {i < data.length - 1 ? <img src={arrow} className='stepper-arrows' alt='step-arrows'></img> : null}
                                </div>
                                <h5 className={`stepper-item-heading my-4 ${activeStep === i ? 'active': ''}`}><Translate>{item.name}</Translate></h5>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Stepper