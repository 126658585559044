import React from 'react'
import './style.css'
import { Translate } from 'react-auto-translate'

const Paragraph = ({activeStep, content}) => {

    // Fetch content
    const getContent = () => {
        if (activeStep === 0) {
           return (
                <div className='paragraph-container'>
                        <div>
                            <h1 className='heading'><Translate>Welcome to Soulful Synergy! We're thrilled that you're considering applying for our Electric Vehicle (EV) program!</Translate></h1>
                            <h5 className='content'><Translate>Rev up your career in Electric Vehicles (EV) with our intensive training program. Delve into the latest advancements in Transportation Electrification (TE) and Electric Vehicle Charging Equipment (EVCE) over three comprehensive modules:</Translate><br/><br/>
                                <strong><Translate>1. TE Basics (3 Hours)</Translate>:</strong> <Translate>Gain fundamental insights into Transportation Electrification, exploring the core concepts and industry foundations.</Translate><br/>
                                <strong><Translate>2. EVCE Site Feasibility (3 Hours)</Translate>:</strong> <Translate>Explore the intricacies of EVCE Site Feasibility, understanding the assessment and planning required for successful installations.</Translate><br/>
                                <strong><Translate>3. EVCE Installation & Managed Charging (3 Hours)</Translate>:</strong> <Translate>Master EVCE Installation and the principles of Managed Charging, ensuring efficiency and optimal performance.</Translate><br/><br/>

                                <Translate>In just 9 hours, our expert instructors will equip you with the tools and knowledge to excel in the dynamic field of electric mobility. Earn a Certificate of Completion for each module, recognizing your expertise.</Translate><br/>

                                <Translate>
                                 {/* This training is free for eligible New York State residents involved in EVCE installations or similar projects. Register now using your work email for full subsidy eligibility. Don't miss the chance to transform your future and contribute to a greener tomorrow! Organizations with 20 completed course participants qualify for a complimentary site feasibility assessment.
                                 */}
                                 This training is <strong>FREE</strong> for eligible New York State workers currently involved in EVCE installations or similar projects or will be in the future. Register now using your work email for full subsidy eligibility. Don't miss the chance to transform your future and contribute to a greener tomorrow! Organizations with 20 completed course participants qualify for a complimentary site feasibility assessment.
                                </Translate><br/><br/>

                                <Translate>Contact</Translate> <u>EVcharging@SoulfulSynergy.org</u> <Translate>to coordinate a specialized training session for your team.</Translate>
                            </h5>
                        </div>
                </div>
           )
        } else {
            return (
                <div>
                    <div className='paragraph-step-container'>
                        <h1 className='heading'><Translate>{content?.title || ''}</Translate></h1>
                        <div className='d-flex justify-content-evenly align-item-center mt-5'>
                            <div className={`content-container ${!content?.bordered ? 'no-border' : ''}`}>
                                <h1 className='heading'><Translate>{content?.heading || ''}</Translate></h1>
                                <div className='image-text-container'>
                                    <div className='image-container'>
                                        <img className={activeStep === 6 ? 'submitted-image' : ''} src={content?.image} style={{height : content?.height ? content?.height : '200px', width: content?.width ? content?.width : '350px'}}></img>
                                    </div>
                                    <div className='text-container'>
                                        <div>
                                            <h1 className='text-container-descrption-title'><Translate>{content?.descriptionTitle || ''}</Translate></h1>
                                            <h5 className='text-container-descrption-content'>
                                                <Translate>{content?.descriptionContent || ''}</Translate>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            {getContent()}
        </div>
    )
}

export default Paragraph