import React, { useState } from 'react'
import { useEffect } from 'react';
import { Translate } from 'react-auto-translate';
import { Badge } from 'reactstrap';
// import './index.css'

const SimpleCheckboxWithOther = (props) => {
    // console.log(props)

    const [otherField, setOtherField] = useState(false)
    const [otherFieldData, setOtherFieldData] = useState('')
    const [editable, setEditable] = useState(false)
    const [hideCancel, setHideCancel] = useState(false)
    const [valuesLength, setValuesLength] = useState(null)
    const [disabled, setDisabled] = useState(props?.activeStep === 5 ? true : false)

    useEffect(() => {
      if (props?.completeQuestionData?.value) {
        setValuesLength((props?.completeQuestionData?.value).length)
      }
    }, [])
    
    useEffect(() => {
      if (valuesLength) {
        if ((valuesLength > (props?.completeQuestionData?.value).length) || (valuesLength < (props?.completeQuestionData?.value).length)) {
          setHideCancel(true)
        }
        console.log("value:------------------", valuesLength, (props?.completeQuestionData?.value).length, (valuesLength > (props?.completeQuestionData?.value).length) || (valuesLength < (props?.completeQuestionData?.value).length));
      }
    }, [props?.completeQuestionData?.value])
    
    const handleClickCancel = () => {
        setEditable(false)
        setHideCancel(false)
        setDisabled(true)
    }

    const handleChange = (event, option) => {
        console.log("option23: ", event, option);
        if (option === "Other") {
            setOtherField(event.target.checked)
            if (event.target.checked === false) {
                let valueArray = props.item['value']
                let updated = props.item['value']
                valueArray.forEach((answerItem, i) => {
                    if ((answerItem.split(':'))[0] === 'Other') {
                        console.log("answerItem.substring(0, 6): ", answerItem, i, (answerItem.split(':'))[0] === 'Other', (answerItem.split(':'))[0]);
                        updated = updated.filter((item, index) => (item.split(':'))[0] !== 'Other')
                        console.log("updated: "+i, updated);
                    }
                })
                props.item['value'] = updated
                props.item['otherValue'] = ''
                setOtherField('')
                setOtherFieldData('')
            } else {
                if (props?.item?.dataSet?.isRequired && (props.item['otherValue']).length <= 0) {
                    props.item.dataSet.isError = true
                    props.item.dataSet.errorMessage = 'Required'
                } else {
                    props.item.dataSet.isError = false
                    props.item.dataSet.errorMessage = ''
                }
                const newArray = props.item['value']
                const filteredData = newArray.filter((selectedOption) => selectedOption !== "None of the above")

                props.item['value'] = [...filteredData, `Other`]
            }
        } else {
            let value = props.item['value']
            let newArray = props.item['value']
            if (value.indexOf(event.target.value) !== -1) {
                const filteredData = value.filter((selectedOption) => selectedOption !== event.target.value)
                props.item['value'] = filteredData
            } else {
                console.log("event.target.value: ", option);
                newArray.push(option)
                if (option === "None of the above") {
                    props.item['value'] = ["None of the above"]
                    props.item.otherValue = ''
                    setOtherFieldData('')
                    setOtherField(false)
                } else {
                    const newArray = props.item['value']
                    const filteredData = newArray.filter((selectedOption) => selectedOption !== "None of the above")
                    props.item['value'] = filteredData
                }
            }
            console.log("values: ", value, newArray, option);
            props.item.dataSet.isError = false
            props.item.dataSet.errorMessage = ''
        }
        props?.validateData()
    }

    const handleOtherField = (event) => {
        if ((props?.item['value']).length === 0 && (props.item['otherValue']).length === 0) {
            props.item.dataSet['isError'] = true
            props.item.dataSet['errorMessage'] = 'Required'
        } else {
            props.item.dataSet['isError'] = false
            props.item.dataSet['errorMessage'] = ''
        }
        props.item['otherValue'] = event.target.value
        let valueArray = props.item['value']
        let updated = props.item['value']
        valueArray.forEach((answerItem, i) => {
            if (answerItem.includes('Other: ')) {
                updated = updated.filter((item, index) => i !== index)
            }
        })
        updated.push(`Other: ${event.target.value}`)
        console.log("updated: ", updated);
        props.item['value'] = updated
        props?.validateData()
    }

    useEffect(() => {
        const valueArray = (props?.item?.value) || []
        console.log("valueArray: ", valueArray);
        if (valueArray.length > 0) {
            for (let i = 0; i < valueArray.length; i++) {
                const option = (valueArray[i]).trim()
                console.log("option 1:", option, option.substring(0, 6));
                if (option.substring(0, 6) === "Other:") {
                    // otherFieldValue = option
                    setOtherFieldData((option.split(':'))[1])
                    console.log("option:", option);
                }
            }
        }
    }, [props?.item?.value])

    const newValueArray = props?.item?.value || []
    // Get Other Field Data
    // const otherFieldDataIndex = (props?.item?.value)
    newValueArray.forEach((option) => {
        option = option.trim()
        if (option.substring(0, 6) === "Other: ") {
            // console.log("option:", option);
        }
    })
    // console.log("props?.item?.value: ", newValueArray);
    

    // console.log("otherFieldDataIndex: ", otherFieldDataIndex);

    const getOption = (option) => {
        if (option.includes('http')) {
            return <p><span>{(option.split('(http://cleanenergyacademy.org)'))[0]}</span><span>
                    <a href='http://cleanenergyacademy.org' target={'_blank'}> http://cleanenergyacademy.org</a>
                </span>
                </p>
        } else {
            return <span className={`${props?.formType} option-label`}><Translate>{option}</Translate></span>
        }
    }
    return (
        <div className={`single-group-child ${props?.formType} special-component ${props?.btnDisabled ? "disabled" : ''} ${props?.item?.dataSet['isError'] ? 'error-in-question' : ''}`}>
            {/* <strong className={`question-element ${props?.formType} special-component`}><Translate>{props?.item?.dataSet?.question}</Translate>{props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}{props?.activeStep === 5 ? <Badge className='mx-2 round' color={editable ? 'success' : 'warning'}  role='pill' style={{cursor: 'pointer', color: editable ? 'white' : 'black'}} onClick={() => {setEditable(!editable); setHideCancel(editable)}}>{editable ? <Translate>Save</Translate> : <Translate>Edit</Translate>}</Badge>: null}{props?.activeStep === 5 && (editable && hideCancel === false)? <Badge className='mx-2 round' color={'secondary'}  role='pill' style={{cursor: 'pointer', color: 'white'}} onClick={() => handleClickCancel()}>{<Translate>Cancel</Translate>}</Badge>: null}</strong> */}
            <strong className={`question-element ${props?.formType} special-component`}><Translate>{props?.item?.dataSet?.question}</Translate>{props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}{props?.activeStep === 5 ? <Badge className='mx-2 round' color={editable ? 'success' : 'warning'}  role='pill' style={{cursor: 'pointer', color: editable ? 'white' : 'black'}} onClick={() => {setEditable(!editable); setHideCancel(editable); setDisabled(editable)}}>{editable ? <Translate>Save</Translate> : <Translate>Edit</Translate>}</Badge>: null}{props?.activeStep === 5 && (editable && hideCancel === false)? <Badge className='mx-2 round' color={'secondary'}  role='pill' style={{cursor: 'pointer', color: 'white'}} onClick={() => handleClickCancel()}>{<Translate>Cancel</Translate>}</Badge>: null}</strong>
            <div className={`main-options-container ${props?.formType} ${props?.divisions}`}>
                {(props?.item?.dataSet?.options).map((option, i) => {
                    return (
                        <div className={`option-container check-option${editable ? '1' : ''} ${(props?.item?.value).includes(option || 'Other') ? 'show' : props?.activeStep === 5 ? 'hide' : 'show'}`} key={i}>
                            <input type="checkbox" id={(option).toLowerCase()}
                            name={props?.item?.dataSet?.groupName}
                            defaultValue={option} 
                            disabled={props?.btnDisabled || disabled}
                            checked={(props?.item?.value).includes(option || 'Other')}
                            onChange={(event) => {handleChange(event, option)}}
                            >
                            </input>
                            {getOption(option)}
                            {/* <span>{option}</span> */}
                        </div>
                    )
                })}
            </div>

            {otherField || (props?.item?.value).includes('Other') ? <input id={'other'}
            name={props?.item?.groupName}
            className='w-100 mb-2'
            disabled={disabled}
            // value={option} 
            defaultValue={otherFieldData}
            onChange={(event) => handleOtherField(event)}/> : null
            }

            {props?.item?.dataSet['isError'] ? <p className='error-message-container'><Translate>{props?.item?.dataSet['errorMessage']}</Translate></p> : null}
        </div>
    )
}

export default SimpleCheckboxWithOther

// We want one component for one question type. But in your case you make one one component for input, radio, checkbox and then using all these for making one question component