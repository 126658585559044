import React, { useEffect, useState } from 'react'
import { Translate } from 'react-auto-translate'

const Step5 = ({start, end, formData, setFormData, component}) => {
    const [stepQuestions, setStepQuestions] = useState(null)

    useEffect(() => {
        setStepQuestions(formData)
    }, [formData])
    
    if (!stepQuestions) {
        return <p>Loading....</p>
    }
    return (
        <div className='main-question-container'>
            <div style={{paddingInline: '70px', marginBlock: '20px'}}>
                <h4 style={{fontSize: '18px', fontWeight: '600'}}><Translate>Fill out the information below</Translate></h4>
            </div>
            <div className='inner-question-container no-bg'>
                {Object.keys(stepQuestions).map((eachQuestion, i) => {
                    if ((i + 1) >= start && (i + 1) <= end) {
                        return <div key={'step1'+i}>
                            {component(stepQuestions[eachQuestion])}
                        </div>
                    }
                })}
            </div>
        </div>
    )
}

export default Step5