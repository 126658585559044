import React from 'react'
import { Translate } from 'react-auto-translate'
import '../../../Inputs.css'
const SoulfulParagraph = () => {
  return (
    <div>
        <br/>
        <h3 className='centertext soulful text-white text-font-sf'>
          <Translate>
            Welcome to Soulful Synergy, LLC
        </Translate>
        </h3>
        <br/>
      <p className='para soulful text-white'>
        <Translate>
          Get ready to supercharge your knowledge and ignite your career in electric vehicles (EV)! Join our cutting-edge EV training program, where you'll immerse yourself in the latest transportation electrification advancements and industry insights. Become the leader in your field!
        </Translate>
      </p>
          {/* <br/>
          <br/> */}
          <ul>
            <li>
              <p className='para soulful text-white'>
                <Translate>
                 Module 1 - Transportation Electrification (TE) Basics (3 Hours)
                </Translate>
              </p>
            </li>
          {/* <br/>
          <br/> */}
          <li>
            <p className='para soulful text-white'>
              <Translate>
                Module 2 - Electric Vehicle Charging Equipment (EVCE) Site Feasibility (3 Hours)
              </Translate>
            </p>
          </li>
          {/* <br/>
          <br/> */}
          <li>
            <p className='para soulful text-white'>
              <Translate>
                Module 3 - EVCE Installation & Managed Charging (3 Hours)
              </Translate>
            </p>
          </li>
          </ul>
          {/* <br/>
          <br/> */}
          <p className='para soulful text-white'>
            <Translate>
              Over the course of 9 intensive hours, our expert instructors will empower you with the tools and expertise to excel in the dynamic world of electric mobility. Don't miss out on this opportunity to transform your future.
            </Translate>
          </p>
      {/* <br /><br /> */}
      <p
      className='para soulful text-white'
      ><i>
        <Translate>Certificate of Completion will be awarded upon completion of each module</Translate>
      </i>
      </p>
        <p><span className='mb-0 text-white text-bolder text-font-sf' style={{fontWeight: 800}}><Translate>Register now and embark on an exciting journey towards a greener, more sustainable tomorrow!</Translate></span></p>
      <p className='para soulful text-white'>
        <Translate>
          Training is available free of charge to New York State residents who are working directly or indirectly on electric vehicle charging equipment (EVCE) installations or similar projects, or who may be involved in these or similar projects at their organization in the future. Candidates should apply using their work email to help confirm eligibility. Work email is not required, but preferred. Eligible participants will have the training costs fully subsidized by NYSERDA.
        </Translate>
      </p>

      <p className='para soulful text-white'>
        <Translate>
          Organizations who have 20 students complete the course (all 3 modules), are eligible to receive a free site feasibility assessment from our team of engineering professionals. You may contact EVcharging@SoulfulSynergy.org if you’d like to coordinate a unique training session specifically for your team.
        </Translate>
      </p>
      <br/>
      <span className='soulful-title' style={{textDecoration: 'underline', textUnderlineOffset: 5, textDecorationThickness: 2}}>
        <Translate>
          Applicant Information
        </Translate>
      </span>
    </div>
  )
}

export default SoulfulParagraph
