import React, { useEffect } from 'react';

const ScriptInjector = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      gtag('event', 'conversion', {'send_to': 'AW-11021716220/LL0PCJ7a-IkYEPyVyIcp'});
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default ScriptInjector;
