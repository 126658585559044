import React, { useEffect, useState } from 'react'
import Checkbox from './../../Inputs/Checkbox'
import FormButton from './../../Components/FormButton'
import './../../Inputs.css'
import SimpleRadio from './../../Components/Radio/SimpleRadio'
import { ToastContainer, toast } from 'react-toastify';
import { GetCertificates, getCoursePreferences, getCoursesByPriority, getReferrals } from './../../Apis/courses'
import Input from './../../Inputs/Input'
import CreatableSelect from 'react-select/creatable'
import { StandaloneSearchBox } from "@react-google-maps/api";
import { checkUserEmail, getModulePreferences, getStates } from './../../Apis/studentApplication'
import { Badge, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import GoogleMapAddress from './../../Components/Map/map'
import SimpleRadioWithOther from './../../Components/Radio/SimpleRadioWithOther/simpleRadioWithOther'
import SimpleCheckboxWithOther from './../../Components/Checkbox/SimpleChcekBoxWithOther/simpleCheckboxWithOther'
import FormSubmitted from './../../assets/submitted.png'
import moment from 'moment/moment'
import MapPin from 'feather-icons-react/build/IconComponents/MapPin' 
import {Colors} from './../../utils/colors'
import DOB from './../../Inputs/Dob'
import RadioHavingOptionChildren from './../../Components/Radio/RadioHavingOptionChildrens'
import { Translate } from 'react-auto-translate'
import ReactGA from "react-ga4"
import SoulfulParagraph from './components/paragraph'
import { SoulfulStudentApplication } from '../../Apis/soulfulForms'
import { loadModules } from "esri-loader"

const SoulFulStudentApplication = () => {
  // ** Form Data

  const [certificationsList, setCertificationsList] = useState(["LEED",
    "BPI",
    "G-Pro",
    "AEE",
    "NABCEP",
    "OSHA",
    "EPA",
    "WELL AP",
    "EVITP",
    "Other",
    "None of the above"
  ])
  const [referralList, setReferralList] = useState([])
  const [coursePreferences, setCoursePreferences] = useState([]);
  const [interestedCoursePreferences, setInterestCoursePreferences] = useState([]);
  const [states, setStates] = useState([]);
  const [show, setShow] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [Lat, setLat] = useState('')
  const [Lng, setLng] = useState('')
  const [googleFormattedAddress, setGoogleFormattedAddress] = useState('')
  const [modalName, setModalName] = useState('')
  const [cancelClicked, setCancelClicked] = useState(false)
  const [userExistsData , setUserExistsData] = useState({})
  const [modulePreferences, setModulePreferences] = useState([])

  const [loader, setLoader] = useState(false)

    // ** Check is Disadvantage Communities
  const [IsDisadvantage, setIsDisadvantage] = useState('no')

  // ** Verify Email
  const [resendEmailBtnClicked, setResendEmailBtnClicked] = useState(false)

  // Make Options For Interested Course
  const interestedCoursePreferencesOptions = []
  interestedCoursePreferences.forEach(option => {
    interestedCoursePreferencesOptions.push(option['line1'])
  })

  // Make Options For Who Referred You?
  const refereralOptions = []
  referralList.forEach(option => {
    refereralOptions.push(option['name'])
  })
   
  //  console.log("refereralOptions: ", refereralOptions);
   
   // Make Options For Course Interested?
   const interestedCoursesOptions = []
   coursePreferences.forEach(option => {
    interestedCoursesOptions.push(option)
   })
   
// city, date, versionName
  const [formData, setFormData] = useState(
     { 
         email: {value: '', dataSet: {question: "Email", isRequired: true, formDataKey: 'email', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', isEmail: true, userVerified: false, notifyMe: true, emailCommunication: true}, 
         
         fName: {value: '', dataSet: {question: "First Name", isRequired: true, formDataKey: 'fName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true}, 

         mName: {value: '', dataSet: {question: "Last Name", isRequired: true, formDataKey: 'mName', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true},

         phoneNumber: {value: '', dataSet: {question: "Primary Phone Number", isRequired: true, formDataKey: 'phoneNumber', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true, notifyMe: true, smsCommunication: true},

         address: {value: '', dataSet: {question: "Address", isRequired: true, formDataKey: 'address', isError: false, errorMessage: ''}, type: 'simpleInputWithMap', inputType: 'text', disable: true}, 
         
         state: {value: '', dataSet: {question: "State", isRequired: true, formDataKey: 'state', isError: false, errorMessage: ''}, type: 'dropdown', inputType: 'text', disable: true},
         
         city: {value: '', dataSet: {question: "State", isRequired: false, formDataKey: 'state', isError: false, errorMessage: ''}, type: 'hidden', inputType: 'text', disable: true},

         zipcode: {value: '', dataSet: {question: "Zip Code", isRequired: true, formDataKey: 'zipcode', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'number', disable: true},

         isEighteen: {value: '', dataSet: {question: "Date of Birth:", options: ["Yes", "No"], isRequired: true, groupName: 'isEighteen', formDataKey: 'isEighteen', isError: false, errorMessage: ''},type: 'dob', disable: true}, 
         
         linkedin: {value: '', dataSet: {question: "LinkedIn URL", isRequired: false, formDataKey: 'linkedin', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', socialLink: true, disable: true},
         //  reasonEnroll: {value: '', dataSet: {question: "Why are you interested in enrolling in our energy efficiency training program?", options: ["Looking for a job", "Looking for career advancement", "Looking to change careers", "Interested in learning more about the industry", ], isRequired: true, groupName: 'reasonEnroll', formDataKey: 'reasonEnroll', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
        //  courceInterested: {value: '', dataSet: {question: "Please indicate which course you are most interested in attending. (Select the one that most applies to you)", options: ['Module 1 - Transportation Electrification (TE) Basics (3 Hours)', 'Module 2 - Electric Vehicle Charging Equipment (EVCE) Site Feasibility (3 Hours)', 'Module 3 - EVCE Installation & Managed Charging (3 Hours)', 'All of the above'], isRequired: true, groupName: 'gender', formDataKey: 'courceInterested', isError: false, errorMessage: ''}, type: 'simpleRadio', isChildren: false, disable: true}, 
 
         courceInterested : {value: '', dataSet: {question: "Please indicate which course you are most interested in attending. (Select the one that most applies to you)", options: [{value: "Module 1 - Transportation Electrification (TE) Basics (3 Hours)", showChildren: false, childrens: modulePreferences}, {value: 'Module 2 - Electric Vehicle Charging Equipment (EVCE) Site Feasibility (3 Hours)', showChildren: false, childrens: modulePreferences}, {value: 'Module 3 - EVCE Installation & Managed Charging (3 Hours)', showChildren: false, childrens: modulePreferences}, {value: 'All of the above', showChildren: false, childrens: modulePreferences}], isRequired: true, groupName: 'courceInterested', formDataKey: 'courceInterested', isError: false, errorMessage: ''},type: 'radioWithExpander', disable: true, isVisible: true}, 

         additionalCourse: {value: [], dataSet: {question: "Please indicate any additional courses you may be interest in taking? (Select the one(s) that most applies to you)", options: ["Battery Storage", "Construction Safety", "Design and Planning", "Electrification", "Energy Efficiency", "Energy Modeling", "Engineering, Software", "Green Roof Installation", "Renewable Energy", "Transportation", "Other"], isRequired: false, groupName: 'additionalCourse', formDataKey: 'additionalCourse', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
         
         currentEmploymentStatus : {value: '', dataSet: {question: "Select which best describes your employment situation", options: [{value: "Employed (Full Time)", showChildren: false, childrens: ["Employed full-time in clean energy", "Employed full-time not in clean energy"]}, {value: "Underemployed (Part Time)", showChildren: false, childrens: ["Employed part-time in clean energy", "Employed part-time not in clean energy"]}, {value: 'Self-employed', showChildren: false, childrens: ["Self-Employed Full Time in clean energy", "Self-Employed Full Time not in clean energy", "Self-Employed Part Time in clean energy", "Self-Employed Part Time not in clean energy"]}, {value: 'Unemployed', showChildren: false, childrens: []}], isRequired: true, groupName: 'currentEmploymentStatus', formDataKey: 'currentEmploymentStatus', isError: false, errorMessage: ''},type: 'radioWithExpander', disable: true}, 
         
         workOrganization: {value: '', dataSet: {question: "Affiliated Organization (Place of work)", isRequired: true, formDataKey: 'workOrganization', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true, isVisible: true},
 
         workOrganizationSize : {value: '', dataSet: {question: "Number of Employees:", options: [{value: "Small", showChildren: false, childrens: ["0-49 employees", "50-249 employees", "250-999 employees", "1000-1499 employees"]}, {value: "Medium", showChildren: false, childrens: ["1500-1999 employees"]}, {value: 'Large', showChildren: false, childrens: ["2000 or more employees"]}], isRequired: true, groupName: 'workOrganizationSize', formDataKey: 'workOrganizationSize', isError: false, errorMessage: ''},type: 'radioWithExpander', disable: true, isVisible: true}, 
        
         employedInEVsector: {value: '', dataSet: {question: "Are you currently employed in an industry related to electric vehicles or clean transportation?", options: ["Yes", "No"], isRequired: true, groupName: 'employedInEVsector', formDataKey: 'employedInEVsector', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true},

         detailsCurrentRole: {value: '', dataSet: {question: "Please provide details about your current industry and role:", isRequired: true, formDataKey: 'detailsCurrentRole', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true, isVisible: true},
         
         //  experienceInsideCleanEnergy: {value: '', dataSet: {question: "How many years of experience do you have working inside the clean energy industry?", options: ['None','1 year',"2 years","3 years","4 years","5 or more years"], isRequired: true, groupName: 'experienceInsideCleanEnergy', formDataKey: 'experienceInsideCleanEnergy', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
         
         futurePlan: {value: '', dataSet: {question: "Do you plan to work in this kind of projects in the future?", options: ["Yes", "No"], isRequired: true, groupName: 'futurePlan', formDataKey: 'futurePlan', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true, isVisible: false},
         
         //  experienceOutsideCleanEnergy: {value: '', dataSet: {question: "How many years of experience do you have working outside the clean energy industry?", options: ['None','1 year',"2 years","3 years","4 years","5 or more years"], isRequired: true, groupName: 'experienceOutsideCleanEnergy', formDataKey: 'experienceOutsideCleanEnergy', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 
         

        //  enrolledInCourses: {value: [], dataSet: {question: "Have you taken one of our courses? (Select all that apply to you)", options: ["Solar installer training ", "Green Construction", "Asbestos", "BPI Multifamily", "Flagger / scaffolding", "30 Hour OSHA", "10 hour (SST) site safety training", "None of the above"], isRequired: true, groupName: 'enrolledInCourses', formDataKey: 'enrolledInCourses', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 
         
        //  currentEmploymentStatus : {value: '', dataSet: {question: "Select which best describes your employment situation", options: [{value: "Employed", showChildren: false, childrens: ["Employed full-time in clean energy", "Employed full-time not in clean energy"]}, {value: "Underemployed", showChildren: false, childrens: ["Employed part-time in clean energy", "Employed part-time not in clean energy"]}, {value: 'Self-employed', showChildren: false, childrens: ["Self-Employed in clean energy", "Self-Employed not in clean energy"]}, {value: 'Unemployed', showChildren: false, childrens: []}], isRequired: true, groupName: 'currentEmploymentStatus', formDataKey: 'currentEmploymentStatus', isError: false, errorMessage: ''},type: 'radioWithExpander', disable: true}, 
         
         //  experienceEEC: {value: '', dataSet: {question: "How many years of experience do you have in the energy efficiency sector?", options: ["<5 years of experience in the clean energy industry", "<5 years of experience outside the clean energy industry", ">5 years of experience in the clean energy industry", ">5 years of experience outside the clean energy industry"], isRequired: true, groupName: 'experienceEEC', formDataKey: 'experienceEEC', isError: false, errorMessage: ''}, type: 'simpleRadio', isChildren: false, disable: true}, 

         //  jobInterested: {value: [], dataSet: {question: "Please select the job roles that interest you. Check all that apply.", options: ["Sales and Outreach", "Auditor and Junior Engineer", "Design and Engineering", "Site Inspector", "Installation and Contracting", "Inspection and M&V (Measurement & Verification)", "Operations & Maintenance", "Clean Energy Instructor"], isRequired: true, groupName: 'jobInterested', formDataKey: 'jobInterested', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 
         
         pastExperienceAreas: {value: [], dataSet: {question: "Do you have any past experience with any of the following areas?", options: ["Electrical Vehicles", "Electric Vehicle Charging Stations", "Electrical Panels", "Battery Storage", "Property Management", "Facilities Management", "Construction Management", "Lighting & Electrical Efficiency", "HVAC & Mechanical", "Plumbing & Heating", "Carpentry & Envelope", "Solar PV", "Other Renewable Energy", "None of the above"], isRequired: true, groupName: 'pastExperienceAreas', formDataKey: 'pastExperienceAreas', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 

         certifications: {value: [], otherValue: '', dataSet: {question: "Do you have any green industry certifications?", options: certificationsList, isRequired: true, groupName: 'certifications', formDataKey: 'certifications', isError: false, errorMessage: ''}, type: 'checkboxWithOther', disable: true}, 
         
        //  seekingEmployment: {value: '', dataSet: {question: "Are you currently actively looking for employment opportunity?", options: ["Yes", "No"], isRequired: true, groupName: 'seekingEmployment', formDataKey: 'seekingEmployment', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 
         
        //  optedPlacementSupport: {value: '', dataSet: {question: "If seeking employment, would you be interested in receiving career services and/or job placement support?", options: ["Yes", "No"], isRequired: true, groupName: 'optedPlacementSupport', formDataKey: 'optedPlacementSupport', isError: false, errorMessage: ''}, type: 'simpleRadio', isChildren: false, disable: true, isVisible: false}, 

        highestEducation: {value: '', dataSet: {question: "What is your highest level of completed education?", options: ["Have not completed high school or equivalent", "High School/HSE/GED", "Associates Degrees", "Bachelors Degrees", "Graduate Degree", "Post Graduate"], isRequired: true, groupName: 'highestEducation', formDataKey: 'highestEducation', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        receivingEV: {value: '', dataSet: {question: "Would you be interesting in receiving EV charging consulting support or having a site feasibility assessment conducted for one or more of your sites?", options: ["Yes", "No", "Would like to learn more"], isRequired: true, groupName: 'receivingEV', formDataKey: 'receivingEV', isError: false, errorMessage: ''}, type: 'simpleRadio', disable: true}, 

        //  recentlyGraduated: {value: '', dataSet: {question: "Are you currently a student or a recent graduate (in the last 12 months) from a degree program at a college or university?", options: ["Yes", "No"], isRequired: true, groupName: 'recentlyGraduated', formDataKey: 'recentlyGraduated', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 
         
         NYSERDA_priorityPopulations: {value: [], dataSet: {question: "Which of these do you identify with? (Select all that apply to you)", options: ["Veterans", "Native Americans", "Individuals with disabilities", "Immigrant", "Refugee", "English as a second language (ESL)", "1st Generation College", "2nd Generation College", "Low income individuals - (60% of NYS median income or receiving public assistance)", "Unemployed plant workers", "Previously incarcerated Individuals", "Homeless Individuals", "16 to 24 years olds in work preparedness training programs that include energy related technical Training", "Single parents", "None of the above"], isRequired: true, groupName: 'NYSERDA_priorityPopulations', formDataKey: 'NYSERDA_priorityPopulations', isError: false, errorMessage: ''}, type: 'simpleCheckbox', disable: true}, 
 
        //  NYSERDA_disadvantagedCommunity: {value: '', dataSet: {question: "Do you reside in an area identified by NYSERDA as a 'disadvantaged community' ? (Click the link below and type address into the search bar located above the map)", options: ["Yes", "No"], isRequired: true, groupName: 'NYSERDA_disadvantagedCommunity', formDataKey: 'NYSERDA_disadvantagedCommunity', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 
 
         sex: {value: '', city: '', stateShortcode: '', dataSet: {question: "Do you identify with any of the following?", options: ["Male", "Female", "Non-binary", "Transgender", "Intersex", "I prefer not to say"], isRequired: true, groupName: 'sex', formDataKey: 'sex', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 
         
         pronoun: {value: '', dataSet: {question: "How would you like to be addressed (your pronouns)?", options: ["He/Him", "She/Her", "They/Them", "Other"], isRequired: false, groupName: 'pronoun', formDataKey: 'pronoun', isError: false, errorMessage: ''},type: 'simpleRadioWithOther', disable: true}, 

        //  genderIdentity: {value: '', dataSet: {question: "What’s your gender identity?", options: ["Transgender", "I prefer not to say", "Other"], isRequired: false, groupName: 'genderIdentity', formDataKey: 'genderIdentity', isError: false, errorMessage: ''},type: 'simpleRadioWithOther', disable: true},
         
         ethnicity: {value: '', dataSet: {question: "Which of the following ethnicities do you most closely identify with?", options: ["American Indian or Alaska Native (Eg: Navajo nation, Blackfeet tribe, Mayan, Aztec, Native Village or Barrow Inupiat Traditional Government, Nome Eskimo Community, etc)", "Asian (Eg: Chinese, Filipino, Asian Indian, Vietnamese, Korean, Japanese, etc)", "Black or African American (Eg: African American, Jamaican, Haitian, Nigerian, Ethiopian, Somalian, etc)", "Hispanic, Latino or Spanish origin (Eg: Mexican or Mexican American, Puerto Rican, Cuban, Salvadoran, Dominican, Colombian, etc)", "Middle Eastern or North African (Eg: Lebanese, Iranian, Egyptian, Syrian, Moroccan, Algerian, etc)", "Native Hawaiian or Other Pacific Islander (Eg: Native Hawaiian, Samoan, Chamorro, Tongan, Fijian, etc)", "White (Eg: German, Irish, English, Italian, Polish, French, etc)", "I prefer not to say"], isRequired: true, groupName: 'ethnicity', formDataKey: 'ethnicity', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true},
         
         sourceApplication: {value: '', dataSet: {question: "How did you hear about this training program?", options: ["Online advertisement", "Referral", "Other"], isRequired: true, groupName: 'sourceApplication', formDataKey: 'sourceApplication', isError: false, errorMessage: ''},type: 'simpleRadio', disable: true}, 

         referredBy: {value: '', dataSet: {question: "If you were referred by 'Other', who referred you?", isRequired: false, formDataKey: 'referredBy', isError: false, errorMessage: ''}, type: 'simpleInput', inputType: 'text', disable: true, isVisible: false},
         
        //  governmentID: {value: '', dataSet: {question: "Do you have a valid Driver’s License, Passport or any other government issued ID? (Select the one that most applies to you)", options: ["Yes", "No", "Other"], isRequired: true, groupName: 'governmentID', formDataKey: 'governmentID', isError: false, errorMessage: ''}, type: 'simpleRadioWithOther', disable: true}, 

        //  resume: {value: '', dataSet: {question: "If you have a resume, please upload it (NOTE: this is optional)", isRequired: false, formDataKey: 'resume', isError: false, errorMessage: '', allowedFileType: ['docx', 'pdf', 'doc']}, type: 'upload', inputType: 'text', note: 'this is optional', disable: true},
         
        //  coverLetterUpload: {value: '', dataSet: {question: "Cover letter, please upload it (NOTE: this is optional)", isRequired: false, formDataKey: 'coverLetter', isError: false, errorMessage: '', allowedFileType: ['docx', 'pdf', 'doc']}, type: 'upload', inputType: 'text', note: 'this is optional', disable: true},
         
        //  emailVerified: false,
        //  phoneVerified: false
//      gender: {value: '', dataSet: {question: "Gender", options: ["Male", "Female", "Other"], isRequired: true, groupName: 'gender', formDataKey: 'gender', isError: false, errorMessage: ''}, type: 'simpleRadio'},
//      experience: {value: '', dataSet: {question: "How many years of experience do you have in the energy efficiency sector?", options: ["1 Year", "2 Year", "3 Year", "4 Year", "5+ Years"], isRequired: true, groupName: 'anyName', formDataKey: 'experience', isError: false, errorMessage: ''}, type: 'simpleRadio'}, gender: {value: '', dataSet: {question: "Gender", options: ["Male", "Female", "Other"], isRequired: true, groupName: 'gender', formDataKey: 'gender', isError: false, errorMessage: ''}, type: 'simpleRadio'},
//    // ,job:[],firstname:'',lastname:'',linkedin:''
//    job: {value: [], dataSet: {question:"Please select the job roles that interest you. Check all that apply.", options:["Sales and Outreach", "Auditor and Junior Engineer", "Design and Engineering", "Site Inspector"], isRequired:true, groupname:'job', formDataKey:'job', ischecked:false, isError: false, errorMessage: ''}, type: 'simpleCheckbox'}
  })

  console.log("file: ", <Translate/>);

  const obj = {}
  Object.keys(formData).forEach(key => {
    obj[key] = formData[key]['dataSet']['question']
  })

  console.log("obj: ", obj);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
    document.title = "Soulful Student Application"
    getStates().then(res => {
        if(res != null){
            const data = res.data
            const statesArray = []
            for(let i = 0; i < data.length; i++){
              let state = data[i].attributes.stateName;
              statesArray.push({label: state, value: state, isFixed: true})
            }
            setStates(statesArray);
        }else{
            
        }
    }).catch(error => {
        console.log("error: ", error);
    })

    GetCertificates().then(res => {
      if (res !== null) {
        const data = res.data
        // Referral
        const certificationsTable = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].attributes.active) {
            if (data[i].attributes.name !== "None of the above" && data[i].attributes.name !== "Other") {
              certificationsTable.push(
                `${data[i].attributes.name}`
              )
            }
          }
        }
        certificationsTable.push("Other")
        certificationsTable.push("None of the above")
        console.log("certificationsTable: ", certificationsTable);
        setCertificationsList(certificationsTable)
      } else {
        setCertificationsList([])
        toast.error(<Translate>Something went wrong</Translate>)
      }
    }).catch(() => {
      setCertificationsList([])
      toast.error(<Translate>Something went wrong</Translate>)
    })

    getCoursesByPriority().then(res => {
        if (res !== null) {
          const responseData = res.data
          let coursePreferencesPriority = {}
          let interestedCoursePreferences = {}
          let referralPriority = {}
          for (let i = 0; i < responseData.length; i++) {
            if (responseData[i].attributes.type === 1) {
              coursePreferencesPriority = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 2) {
              interestedCoursePreferences = responseData[i].attributes.priorities
            } else if (responseData[i].attributes.type === 3) {
                referralPriority = responseData[i].attributes.priorities
            }
          }

          // Get courses list
          getCoursePreferences().then(res => {
            if (res !== null) {
              const coursePreferencesTable = []
              const interestedCoursePreferencesTable = []
              const data = res.data
              for (let i = 0; i < data.length; i++) {
                if (data[i].attributes.type === 1) {
                  coursePreferencesTable.push({
                    id: data[i].id,
                    line1: data[i].attributes.line1,
                    line2: data[i].attributes.line2,
                    line3: data[i].attributes.line3
                  })
                } else {
                  interestedCoursePreferencesTable.push({
                    id: data[i].id,
                    line1: data[i].attributes.line1
                  })
                }
              }

              // set items in array according to priority

              let coursePreferencesPriorityTableData = []
              Object.keys(coursePreferencesPriority).forEach(key => {
                const priority = coursePreferencesPriority[key] // 29
                ////  console.log("key: ", priority);
                for (let i = 0; i < coursePreferencesTable.length; i++) {
                  if (coursePreferencesTable[i].id === priority) {
                    coursePreferencesPriorityTableData.push(coursePreferencesTable[i])
                  }
                }
              })

              let makeNewArray1 = coursePreferencesTable
              let notPresentPriorityArray1 = makeNewArray1.filter(item => {
                  if (coursePreferencesPriorityTableData.includes(item) === false) {
                    return item
                  }
              })

              for (let i = 0 ; i < notPresentPriorityArray1.length; i++) {
                coursePreferencesPriorityTableData.push(notPresentPriorityArray1[i])
              }

              //////////////////////////////// For Interested Course Preferences ////////////////////////////////

              const interestedCoursePreferencesPriorityTableData = []
              Object.keys(interestedCoursePreferences).forEach(key => {
                const priority = interestedCoursePreferences[key] // 29
                for (let i = 0; i < interestedCoursePreferencesTable.length; i++) {
                  if (interestedCoursePreferencesTable[i].id === priority) {
                    interestedCoursePreferencesPriorityTableData.push(interestedCoursePreferencesTable[i])
                  }
                }
              })

              let makeNewArray = interestedCoursePreferencesTable
              let notPresentPriorityArray = makeNewArray.filter(item => {
                  if (interestedCoursePreferencesPriorityTableData.includes(item) === false) {
                    return item
                  }
              })

              for (let i = 0 ; i < notPresentPriorityArray.length; i++) {
                interestedCoursePreferencesPriorityTableData.push(notPresentPriorityArray[i])
              }

            //  //  console.log("coursePreferencesPriorityTableData: ", interestedCoursePreferencesPriorityTableData);
              setCoursePreferences(coursePreferencesPriorityTableData)
              setInterestCoursePreferences(interestedCoursePreferencesPriorityTableData)
            } else {
              setCoursePreferences([])
              setInterestCoursePreferences([])
            }
          }).catch(() => {
            ////  console.log("error: ", error)
            setCoursePreferences([])
            setInterestCoursePreferences([])
            toast.error(<Translate>Something went wrong 1</Translate>)
          })

          getReferrals().then(res => {
            if (res !== null) {
              const data = res.data
              // Referral
              const ReferralPreferencesTable = []
              for (let i = 0; i < data.length; i++) {
                ReferralPreferencesTable.push({
                  name: `${data[i].attributes.name}`,
                  id: data[i].id
                })
              }

                // set items in array according to priority

                let referrralTableData = []
                Object.keys(referralPriority).forEach(key => {
                  const priority = referralPriority[key] // 29
                  ////  console.log("key: ", priority);
                  for (let i = 0; i < ReferralPreferencesTable.length; i++) {
                    if (ReferralPreferencesTable[i].id === priority) {
                      referrralTableData.push(ReferralPreferencesTable[i])
                    }
                  }
                })

                let makeNewArray1 = ReferralPreferencesTable
                let notPresentPriorityArray1 = makeNewArray1.filter(item => {
                    if (referrralTableData.includes(item) === false) {
                      return item
                    }
                })

                for (let i = 0 ; i < notPresentPriorityArray1.length; i++) {
                  referrralTableData.push(notPresentPriorityArray1[i])
                }
            //   console.log("ReferralPreferencesTable: ", ReferralPreferencesTable, referrralTableData)
            
            console.log("Sort Array of Objects Alphabetically");
            const Std_arr = referrralTableData
            const sortedList = Std_arr.sort((a, b) =>
              a.name.localeCompare(b.name));
              console.log(sortedList);
              console.log("sortedList.indexOf('other'): ", sortedList.indexOf('Other'));
              const newData = sortedList.filter(item => (item.name !== "Other" && item.name !== "Referral"))
              newData.push((sortedList.filter(item => item.name === "Referral"))[0])
              newData.push((sortedList.filter(item => item.name === "Other"))[0])
              setReferralList(newData)
            } else {
              setReferralList([])
              toast.error(<Translate>Something went wrong 2</Translate>)
            }
          }).catch(() => {
            setReferralList([])
            toast.error(<Translate>Something went wrong 3</Translate>)
          })
        } else {
          toast.error(<Translate>Something went wrong 4</Translate>)
          setCoursePreferences([])
          setInterestCoursePreferences([])
        }
    }).catch(error => {
      console.log("error: ", error)
      toast.error(<Translate>Something went wrong</Translate>)
      setCoursePreferences([])
      setInterestCoursePreferences([])
    })

    getModulePreferences().then(res => {
      if (res !== null) {
        const data = res.data
        const modulePreferences = []
        for (let i = 0; i < data.length; i++) {
          modulePreferences.push(data[i].attributes.name)
        }
        setModulePreferences(modulePreferences)
      } else {
        setModulePreferences([])
        toast.error(<Translate>Something went wrong</Translate>)
      }
    }).catch(() => {
      setModulePreferences([])
      toast.error(<Translate>Something went wrong</Translate>)
    })

    // 
  }, [])

  
  useEffect(() => {
    if (interestedCoursePreferencesOptions.length > 0 && formData.additionalCourse.dataSet.options.length === 0)  {
        setFormData({...formData, additionalCourse: {...formData['additionalCourse'], ['dataSet']: {...formData['additionalCourse']['dataSet'], 'options': interestedCoursePreferencesOptions}}})
    }
  }, [interestedCoursePreferencesOptions])

  useEffect(() => {
    if (refereralOptions.length > 0 && formData.sourceApplication.dataSet.options.length === 0)  {
        setFormData({...formData, sourceApplication: {...formData['sourceApplication'], ['dataSet']: {...formData['sourceApplication']['dataSet'], 'options': refereralOptions}}})
      }
  }, [refereralOptions])

  useEffect(() => {
    if (certificationsList.length > 0 && formData.certifications.dataSet.options.length === 0)  {
        setFormData({...formData, certifications: {...formData['certifications'], ['dataSet']: {...formData['certifications']['dataSet'], 'options': certificationsList}}})
      }
  }, [certificationsList])

  useEffect(() => {
    if (modulePreferences.length > 0)  {
        setFormData({...formData, courceInterested: {...formData['courceInterested'], ['dataSet']: {...formData['courceInterested']['dataSet'], options: [{value: "Module 1 - Transportation Electrification (TE) Basics (3 Hours)", showChildren: false, childrens: modulePreferences}, {value: 'Module 2 - Electric Vehicle Charging Equipment (EVCE) Site Feasibility (3 Hours)', showChildren: false, childrens: modulePreferences}, {value: 'Module 3 - EVCE Installation & Managed Charging (3 Hours)', showChildren: false, childrens: modulePreferences}, {value: 'All of the above', showChildren: false, childrens: modulePreferences}]}}})
    }
  }, [modulePreferences])

  useEffect(() => {
    // if (interestedCoursesOptions.length > 0 && formData.courceInterested.dataSet.options.length === 0)  {
    //     // setFormData({...formData, courceInterested: {...formData['courceInterested'], ['dataSet']: {...formData['courceInterested']['dataSet'], 'options': interestedCoursesOptions}}})
    //   }
  }, [interestedCoursesOptions])
  
  console.log("formData: ", formData);

  const [IsLoading, setIsLoading] = useState(false)
   
  const validateData = () => {
    let functionReturn = true
    let validateFormData = formData
    
    Object.keys(validateFormData).forEach(eachQuestion => {
      // Checking Required ------------------------------------------------------------------------------------------------------------------
      if (eachQuestion !== 'emailVerified' && eachQuestion !== "phoneVerified") {
        // console.log("email: ", eachQuestion, (validateFormData[eachQuestion]?.['value']).length);
        if (validateFormData[eachQuestion]?.['value'] !== null) {
          if (validateFormData[eachQuestion]?.['dataSet']?.['isRequired'] && ((validateFormData[eachQuestion]?.['value']).length <= 0 || validateFormData[eachQuestion].value === null)) {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Required'
            functionReturn = false
          } else {
            if (validateFormData[eachQuestion]['dataSet']) {
              validateFormData[eachQuestion]['dataSet']['isError'] = false
              validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
            }
          }
        }
        // Checking Required ------------------------------------------------------------------------------------------------------------------
  
  
        // Check Social Link ----------------------------------------------------------------------------------------------------
        if (eachQuestion === 'socialLink') {
          if (validateFormData[eachQuestion]['socialLink'] !== undefined) {
            // console.log("(EMAIL_REGEX).test(validateFormData[eachQuestion]['value']1: ", (EMAIL_REGEX).test(validateFormData[eachQuestion]['value']))
            const value = validateFormData[eachQuestion]['value']
            if (value.length > 0) {
              if (value.charAt(0) !== ' ') {
                let newLink = ''
                  if (value.toLocaleLowerCase().includes('https://') || value.toLocaleLowerCase().includes('http://')) {
                  } else {
                      newLink = `https://${value}`
                      // linkedinUrl = newLink
                      setFormData({...formData['linkedin'], 'value': newLink})
                  }
                  validateFormData[eachQuestion]['dataSet']['isError'] = false
                  validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
                } else {
                  validateFormData[eachQuestion]['dataSet']['isError'] = true
                  validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'first character not be space'
                  functionReturn = false
              }
           }
          }
        }
        // Check Social Link ----------------------------------------------------------------------------------------------------
  
        if (eachQuestion === 'seekingEmployment') {
          if (validateFormData[eachQuestion]['value'] === "Yes") {
            validateFormData['optedPlacementSupport']['isVisible'] = true
            validateFormData['optedPlacementSupport']['dataSet']['isRequired'] = true
          } else {
            validateFormData['optedPlacementSupport']['isVisible'] = false
            validateFormData['optedPlacementSupport']['value'] = ''
            validateFormData['optedPlacementSupport']['dataSet']['isRequired'] = false
          }
        }
// currentEmploymentStatus

        if (eachQuestion === 'currentEmploymentStatus') {
          if (validateFormData[eachQuestion]['value'] === "Unemployed") {
            validateFormData['workOrganization']['isVisible'] = false
            validateFormData['workOrganization']['value'] = ''
            validateFormData['workOrganization']['dataSet']['isRequired'] = false

            validateFormData['workOrganizationSize']['isVisible'] = false
            validateFormData['workOrganizationSize']['value'] = ''
            validateFormData['workOrganizationSize']['dataSet']['isRequired'] = false
          } else {
            validateFormData['workOrganization']['isVisible'] = true
            // validateFormData['workOrganization']['value'] = ''
            validateFormData['workOrganization']['dataSet']['isRequired'] = true

            validateFormData['workOrganizationSize']['isVisible'] = true
            // validateFormData['workOrganizationSize']['value'] = ''
            validateFormData['workOrganizationSize']['dataSet']['isRequired'] = true
          }
        }

        if (eachQuestion === 'employedInEVsector') {
          if (validateFormData[eachQuestion]['value'] === "Yes") {
            // validateFormData['detailsCurrentRole']['isVisible'] = true
            // validateFormData['detailsCurrentRole']['dataSet']['isRequired'] = true

            validateFormData['futurePlan']['isVisible'] = false
            validateFormData['futurePlan']['dataSet']['isRequired'] = false
          } else if (validateFormData[eachQuestion]['value'] === "No") {
            // validateFormData['detailsCurrentRole']['isVisible'] = false
            // validateFormData['detailsCurrentRole']['value'] = ''
            // validateFormData['detailsCurrentRole']['dataSet']['isRequired'] = false

            validateFormData['futurePlan']['isVisible'] = true
            validateFormData['futurePlan']['dataSet']['isRequired'] = true
          } else {
            validateFormData['futurePlan']['isVisible'] = false
            validateFormData['futurePlan']['dataSet']['isRequired'] = false
          }
        }
        
        if (eachQuestion === 'sourceApplication') {
          if (validateFormData[eachQuestion]['value'] === "Referral" || validateFormData[eachQuestion]['value'] === "Other") {
            validateFormData['referredBy']['isVisible'] = true
            validateFormData['referredBy']['dataSet']['isRequired'] = true
            if (validateFormData[eachQuestion]['value'] === "Referral") {
              validateFormData['referredBy']['dataSet']['question'] = 'If you chose Referral, who referred you?'
            } else {
              validateFormData['referredBy']['dataSet']['question'] = 'If you chose Other, please describe'
            }
          } else {
            validateFormData['referredBy']['isVisible'] = false
            validateFormData['referredBy']['value'] = ''
            validateFormData['referredBy']['dataSet']['isRequired'] = false
          }
        }

        // check validateFormData[eachQuestion]['value'] length equal to 10
        if (eachQuestion === 'phoneNumber') {
          if (validateFormData[eachQuestion]['value'].length !== 13) {
            validateFormData[eachQuestion]['dataSet']['isError'] = true
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = 'Phone number must be 10 digits'
            functionReturn = false
          } else {
            validateFormData[eachQuestion]['dataSet']['isError'] = false
            validateFormData[eachQuestion]['dataSet']['errorMessage'] = ''
          }
        }
      }
    })
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    setFormData(validateFormData)
    return functionReturn
  }

  // Submit Form ----------------------------------------------------------------------------------
  const handleSubmit = () => {
    console.log("validateData(): ", validateData());
    if (validateData()) {
      let dateString = new Date(); 
      dateString = moment(dateString).format('YYYY-MM-DD');
      // setLoader(true)

      // set interested course
      let updatedInterestedCourse = ''
      const dataset = formData['courceInterested']['dataSet']
      for (let i = 0; i < dataset.options.length; i++) {
        if (dataset.options[i].showChildren) {
          updatedInterestedCourse = dataset.options[i].value
          break
        }
      }

      // console.log("updatedInterestedCourse: ", updatedInterestedCourse, formData['courceInterested']['value']);


      SoulfulStudentApplication({data: {
        email: formData['email']['value'],
        firstName: formData['fName']['value'],
        lastName: formData['mName']['value'],
        primaryPhoneNumber: (formData['phoneNumber']['value']).replace(/[^0-9]/g, ''),
        receiveSMSnotification: formData.phoneNumber.smsCommunication,
        address: formData['address']['value'],
        state: formData['state']['value'],
        zipcode: `${formData['zipcode']['value']}`,
        googleAddress: googleFormattedAddress,
        lat: `${Lat}`,
        lng: `${Lng}`,
        dob: formData['isEighteen']['value'],
        linkedin: formData['linkedin']['value'],
        interestedCourse: updatedInterestedCourse,
        additionalCourse: (formData['additionalCourse']['value']).map(item => item).join(','),
        currentEmploymentStatus: formData['currentEmploymentStatus']['value'],

        // New
        workOrganization: formData['workOrganization']['value'],
        workOrganizationSize: formData['workOrganizationSize']['value'],
        futurePlan: formData['futurePlan']['value'],
        receivingEV: formData['receivingEV']['value'],
        coursePreference: updatedInterestedCourse !== formData['courceInterested']['value'] ? formData['courceInterested']['value']: '',
        NYSERDA_disadvantagedCommunity: IsDisadvantage,
        city: formData['sex']['city'].length ? formData['sex']['city'] : formData['city']['value'],
        stateShortcode: formData['sex']['stateShortcode'],
        // New

        // experienceEEC: formData['experienceEEC']['value'],

        // experienceINcleanEnergyIndustry: formData['experienceInsideCleanEnergy']['value'],
        // experienceOUTcleanEnergyIndustry: formData['experienceOutsideCleanEnergy']['value'],
        employedInEVsector: formData['employedInEVsector']['value'] === "Yes",
        detailsCurrentRole: formData['detailsCurrentRole']['value'],

        pastExperienceAreas: (formData['pastExperienceAreas']['value']).map(item => item).join(','),
        certifications: ((formData['certifications']['value']).map(item => item).join(',')) + `${formData['certifications']['otherValue']}`,
        highestEducation: formData['highestEducation']['value'],
        gender: formData['sex']['value'],
        pronoun: formData['pronoun']['value'],
        genderIdentity: null,

        ethnicity: formData['ethnicity']['value'],
        priorityPopulations: (formData['NYSERDA_priorityPopulations']['value']).map(item => item).join(','),
        sourceApplication: `${formData['sourceApplication']['value']}${formData['referredBy']['value'].length > 0 ? ' - ' + formData['referredBy']['value'] : ''}`,

        versionName: "new_soulful_2023",
        formLanguage: localStorage.getItem('newFormFromLanguage') || 'en',
      }}).then(res => {
        if (res !== null) {
          setShow(true)
          setModalName("Thank You")
          toast.success(<Translate>Application Submitted Successfully</Translate>)
          // setTimeout(() => {
          //     handleReloadForm()
          // }, 1500)
        } else {
          setLoader(false)
        }
      }).catch(error => {
        setLoader(false)
        console.log("error: ", error);
        toast.error(<Translate>Something went wrong</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
      })
    } else {
      toast.error(<Translate>Please Fill All The Required Fields</Translate>, {position: 'top-right', autoClose: 2000, hideProgressBar: true})
    }
  }

  const handleReloadForm = () => {
    setCancelClicked(true)
    setModalName('Thank You')
    setShow(false)
    // setTimeout(() => {
      window.location.reload()
    // }, 3000)
  }

  const handleResendEmail = () => {
    checkUserEmail({email: formData?.email.value, type: 'student'}).then(res => {
      console.log("checkUserExists: ", res);
      if (res.status) {
        toast.success('Verification link re-send successfully', { position: 'top-right', autoClose: 2000, hideProgressBar: true })
        setResendEmailBtnClicked(true)
        handleReloadForm()
      } else {
        setResendEmailBtnClicked(false)
      }
    }).catch(error => {
      setResendEmailBtnClicked(false)
      console.log("error: ", error);
      toast.error('Something went wrong!! try again later', { position: 'top-right', autoClose: 2000, hideProgressBar: true })
    })
  }
  // Submit Form ----------------------------------------------------------------------------------

  // Dropdown Componenet
  const handleSelectState = (item) => {
    console.log(item);
    setFormData({...formData, state: {...formData['state'], 'value': item.value}})
  }

  // Address Component
  const handleAdderssByMap = () => {
    setShow(true)
  }

  const handleCancel = () => {
      setShow(false)
  }

  const [referencess, setReference] = useState(null)
  const onLoad = ref => {
    let searchBox = null
    searchBox = ref
    setReference(ref)
  };

  const search = async (googleAddress) => {
    console.log("googleAddress: ", googleAddress);

    const [Map, MapView, FeatureLayer, Search] = await loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/widgets/Search"
    ])

    try {
      const myMap = new Map({
      basemap: "streets-relief-vector"
      })

      console.log("map: ", myMap)
  
      const myView = new MapView({
          container: "viewDiv",
          map: myMap,
          center: [-76, 42.8], 
          zoom: 7
      })

      console.log("map 1: ", myView)
  
      myView.popup.dockOptions = {
      // Disable the dock button so users cannot undock the popup
      buttonEnabled: false
      }
  
      myView.ui._removeComponents(["attribution"])
  
      const Interim_DAC = new FeatureLayer({
      url: "https://services7.arcgis.com/ZR2wjW0JGwgm9bhz/arcgis/rest/services/FinalDisadvantagedCommunities/FeatureServer",
      popupTemplate: {
      // Enable a popup
      title: "Interim DAC" // Show attribute value
      // content: "Clicked Point" // Display text in pop-up
      }
      })

      myMap.add(Interim_DAC)           
      // console.log("map 2: ", Interim_DAC)
  
      const searchWidget = new Search({
      view: myView
      })

      myView.ui.add(searchWidget, {
          position: "top-left",
          index: 2
      })

      searchWidget.search(googleAddress).then(res => {
        // console.log("response: ", res?.results[0]['results'][0]['feature']['geometry']);
        const geometry = res?.results[0]['results'][0]['feature']['geometry']
        // console.log("geometry: ", res?.results[0]['results'][0]['feature']['geometry']);
        getAddress(geometry);
      }).catch(error => {
        // console.log("geometry error: ", error);
      })
      
      function resolveValue(value) {

        console.log("map 5: ", value);
          var geoId = null;
          var userMessage = "";
          console.log("value 12: ", value);
          if(value == null){
              userMessage = "";
              console.log("userMessage - " + userMessage);
              // alert('Address Not Meet')
              setIsDisadvantage('no')
              // document.getElementById('resultDiv').innerHTML = userMessage
          }
          else if(value == "no data found"){
              userMessage = " is outside a DAC";
              console.log("userMessage - " + userMessage);
              // alert('Address Not Meet')
              setIsDisadvantage('no')
              // document.getElementById('resultDiv').innerHTML = userMessage
          }
          else if(value.features.length > 0){
              geoId = value.features[0].attributes.GEOID;
              userMessage = "<br>"+ "This address meets the interim criteria identified for a disadvantaged community.";
              console.log("userMessage - " + userMessage);
              // alert('Address Meet')
              setIsDisadvantage('yes')
              // document.getElementById('resultDiv').innerHTML = userMessage
          }
          else{
              userMessage = "<br>"+ "This address does not meet the interim criteria identified for a disadvantaged community.";
              console.log("userMessage - " + userMessage);
              // alert('Address Not Meet')
              setIsDisadvantage('no')
              // document.getElementById('resultDiv').innerHTML = userMessage
          }
      }

      function getAddress(point) {
          const query = Interim_DAC.createQuery()
          query.geometry = point  
          query.spatialRelationship = "intersects"
          query.returnGeometry = false
          query.outFields = ["GEOID"]
          Interim_DAC.queryFeatures(query)
          .then(function(value) {
              resolveValue(value)
          }, function(err) {
              console.error("This is an error", err)
              // this.error()
          })
      }
  
      searchWidget.on("select-result", function(event) {
          // setIsAddressSearched(true)
          getAddress(event.result.feature.geometry)
      })
      searchWidget.on("search-clear", function() {
          // console.log("cleared")
          // setIsAddressSearched(false)
          // setIsDisadvantage(false)
      })
    } catch (error) {
      // alert('Address Not Meet')
      setIsDisadvantage('no')
    }
  }

  const onPlacesChanged = () => {
    let selectedAddress = referencess.getPlaces()
    let latitude = selectedAddress[0].geometry.location.lat()
    let longitude = selectedAddress[0].geometry.location.lng()

    let addressComponentsArray = [];
    addressComponentsArray = selectedAddress[0].address_components;
    let zipcode = ''
    let state = ''
    let city = ''
    for (let i = 0 ; i < addressComponentsArray.length ; i++) {
        let addObjtypes = addressComponentsArray[i].types
        if (addObjtypes.includes('postal_code')) {
            zipcode = `${addressComponentsArray[i].long_name}`
            console.log("addObjtypes: ", addressComponentsArray[i].long_name);
            // break;
        }
        
        if (addObjtypes.includes('administrative_area_level_1')) {
            state = `${addressComponentsArray[i].long_name}`
            console.log("addObjtypes: ", addressComponentsArray[i].long_name);
            // break;
        }

        if (addObjtypes.includes('locality')) {
            city = addressComponentsArray[i].long_name
        }
    }
    console.log("event: ", selectedAddress[0].geometry.location.lat(), selectedAddress[0].geometry.location.lng(), zipcode)
    setLat(latitude); setLng(longitude);
    setFormData({...formData, address: {...formData['address'], 'value': selectedAddress[0].formatted_address}, zipcode: {...formData['zipcode'], 'value': zipcode}, state: {...formData['state'], 'value': state}, city: {...formData['city'], 'value': city}})
    search(selectedAddress[0].formatted_address)
  };

  const setAddressByMap = (addressComponent) => {
    console.log("addressComponent: ", addressComponent);
    setFormData({...formData, address: {...formData['address'], 'value': addressComponent.formattedAddress}, zipcode: {...formData['zipcode'], 'value': addressComponent.zipcode}, state: {...formData['state'], 'value': addressComponent.state}, city: {...formData['city'], 'value': addressComponent.city}})
    setLat(addressComponent.latitude)
    setLng(addressComponent.longitude)
    setGoogleFormattedAddress(addressComponent.formattedAddress)
    search(addressComponent.formattedAddress)
  }

  // Prefil Form Data
  const prefillApplicationForm = (data) => {
    const userData = data.attributes
    console.log("prefillApplicationForm: ", userData);


    // Check Data For RadioHavingChildrens Component
    let questionOptions = []
    if (userData.currentEmploymentStatus) {
      let value = (userData.currentEmploymentStatus).split(',').map(element => element.trim()) || []

      // if (formData.currentEmploymentStatus.dataSet.options)
      questionOptions = formData.currentEmploymentStatus.dataSet.options
      for (let i = 0 ; i < questionOptions.length; i++) {
        for (let j = 0 ; j < value.length; j++) {
          if ((questionOptions[i].childrens).includes(value[j])) {
            questionOptions[i]['showChildren'] = true
          }
        }
      }
    } else {
      questionOptions = formData.currentEmploymentStatus.dataSet.options
    }

    console.log("questionOptions: ", questionOptions);

    setFormData({...formData, 
      fName: {...formData['fName'], 'value': userData.fName, dataSet: {...formData['fName']['dataSet'], isError: false, errorMessage: ''}, emailCommunication: userData.emailCommunication},
      mName: {...formData['mName'], 'value': userData.mName, dataSet: {...formData['mName']['dataSet'], isError: false, errorMessage: ''}},
      phoneNumber: {...formData['phoneNumber'], 'value': userData.phoneNumber, dataSet: {...formData['phoneNumber']['dataSet'], isError: false, errorMessage: ''}, smsCommunication: userData.smsCommunication},
      address: {...formData['address'], 'value': userData.address, dataSet: {...formData['address']['dataSet'], isError: false, errorMessage: ''}},
      state: {...formData['state'], 'value': userData.state, dataSet: {...formData['state']['dataSet'], isError: false, errorMessage: ''}},
      city: {...formData['city'], 'value': userData.city, dataSet: {...formData['city']['dataSet'], isError: false, errorMessage: ''}},
      zipcode: {...formData['zipcode'], 'value': userData.zipcode, dataSet: {...formData['zipcode']['dataSet'], isError: false, errorMessage: ''}},
      linkedin: {...formData['linkedin'], 'value': userData.linkedin, dataSet: {...formData['linkedin']['dataSet'], isError: false, errorMessage: ''}},
      isEighteen: {...formData['isEighteen'], 'value': userData.isEighteen, dataSet: {...formData['isEighteen']['dataSet'], isError: false, errorMessage: ''}},
      // reasonEnroll: {...formData['reasonEnroll'], 'value': userData.reasonEnroll, dataSet: {...formData['reasonEnroll']['dataSet'], isError: false, errorMessage: ''}},
      experienceInsideCleanEnergy: {...formData['experienceInsideCleanEnergy'], 'value': userData.experienceInsideCleanEnergy !== null ? userData.experienceInsideCleanEnergy : '', dataSet: {...formData['experienceInsideCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      experienceOutsideCleanEnergy: {...formData['experienceOutsideCleanEnergy'], 'value': userData.experienceOutsideCleanEnergy !== null ? userData.experienceOutsideCleanEnergy : '', dataSet: {...formData['experienceOutsideCleanEnergy']['dataSet'], isError: false, errorMessage: ''}},
      // experienceEEC: {...formData['experienceEEC'], 'value': userData.experienceEEC, dataSet: {...formData['experienceEEC']['dataSet'], isError: false, errorMessage: ''}},
      seekingEmployment: {...formData['seekingEmployment'], 'value': userData.seekingEmployment ? userData.seekingEmployment : '', dataSet: {...formData['seekingEmployment']['dataSet'], isError: false, errorMessage: ''}},
      optedPlacementSupport: {...formData['optedPlacementSupport'], 'value': userData.optedPlacementSupport ? userData.optedPlacementSupport : '', dataSet: {...formData['optedPlacementSupport']['dataSet'], isError: false, errorMessage: ''}},
      currentEmploymentStatus: {...formData['currentEmploymentStatus'], 'value': userData.currentEmploymentStatus ? (userData.currentEmploymentStatus) : [], dataSet: {...formData['currentEmploymentStatus']['dataSet'], isError: false, errorMessage: '', options: questionOptions}},
      recentlyGraduated: {...formData['recentlyGraduated'], 'value': userData.recentlyGraduated !== null ? userData.recentlyGraduated : '', dataSet: {...formData['recentlyGraduated']['dataSet'], isError: false, errorMessage: ''}},
      courceInterested: {...formData['courceInterested'], 'value': userData.courceInterested, dataSet: {...formData['courceInterested']['dataSet'], isError: false, errorMessage: ''}},
      additionalCourse: {...formData['additionalCourse'], 'value': userData.additionalCourse ? (userData.additionalCourse).split(',').map(element => element.trim()) : [], dataSet: {...formData['additionalCourse']['dataSet'], isError: false, errorMessage: ''}},
      enrolledInCourses: {...formData['enrolledInCourses'], 'value': userData.enrolledInCourses ? (userData.enrolledInCourses).split(',').map(element => element.trim()) : [], dataSet: {...formData['enrolledInCourses']['dataSet'], isError: false, errorMessage: ''}},
      // jobInterested: {...formData['jobInterested'], 'value': (userData.jobInterested).split(','), dataSet: {...formData['jobInterested']['dataSet'], isError: false, errorMessage: ''}},
      pastExperienceAreas: {...formData['pastExperienceAreas'], 'value': userData.pastExperienceAreas ? (userData.pastExperienceAreas).split(',').map(element => element.trim()) : [], dataSet: {...formData['pastExperienceAreas']['dataSet'], isError: false, errorMessage: ''}},
      highestEducation: {...formData['highestEducation'], 'value': userData.highestEducation, dataSet: {...formData['highestEducation']['dataSet'], isError: false, errorMessage: ''}},
      certifications: {...formData['certifications'], 'value': userData.certifications ? (userData.certifications).split(',').map(element => element.trim()) : null, dataSet: {...formData['certifications']['dataSet'], isError: false, errorMessage: ''}},
      NYSERDA_priorityPopulations: {...formData['NYSERDA_priorityPopulations'], 'value': (userData.NYSERDA_priorityPopulations).split(',').map(element => element.trim()), dataSet: {...formData['NYSERDA_priorityPopulations']['dataSet'], isError: false, errorMessage: ''}},
      // NYSERDA_disadvantagedCommunity: {...formData['NYSERDA_disadvantagedCommunity'], 'value': userData.NYSERDA_disadvantagedCommunity, dataSet: {...formData['NYSERDA_disadvantagedCommunity']['dataSet'], isError: false, errorMessage: ''}},
      sex: {...formData['sex'], 'value': userData.sex, dataSet: {...formData['sex']['dataSet'], isError: false, errorMessage: ''}},
      ethnicity: {...formData['ethnicity'], 'value': userData.ethnicity, dataSet: {...formData['ethnicity']['dataSet'], isError: false, errorMessage: ''}},
      sourceApplication: {...formData['sourceApplication'], 'value': userData.sourceApplication, dataSet: {...formData['sourceApplication']['dataSet'], isError: false, errorMessage: ''}},
      referredBy: {...formData['referredBy'], 'value': userData.referredBy, dataSet: {...formData['referredBy']['dataSet'], isError: false, errorMessage: '', isRequired: userData.sourceApplication === "Referral" || userData.sourceApplication === "Other"}, isVisible: userData.sourceApplication === "Referral" || userData.sourceApplication === "Other"},
      resume: {...formData['resume'], 'value': userData.resume, dataSet: {...formData['resume']['dataSet'], isError: false, errorMessage: ''}},
      coverLetterUpload: {...formData['coverLetterUpload'], 'value': userData.coverLetterUpload ? userData.coverLetterUpload : '', dataSet: {...formData['coverLetterUpload']['dataSet'], isError: false, errorMessage: ''}},
      emailVerified: userData.emailVerified,
      phoneVerified: userData.phoneVerified
    })
  }

  const getContent = (questionObject, singleOrMulti) => {
    if (questionObject !== null) {
      switch(questionObject.type) {
  
        case "simpleRadio":  return (
          <SimpleRadio className={singleOrMulti} item={questionObject['dataSet']} formType="soulful-application" btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData} formData={formData}/>
        )
  
        case "simpleCheckbox":  return(
          <Checkbox className={singleOrMulti} item={questionObject['dataSet']} formType="soulful-application" btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        )
        
        case "simpleInput":  return(
          <Input className={singleOrMulti} setCoursesList={() => {}} setUserExistsData={setUserExistsData} item={questionObject} btnDisabled={btnDisabled} formType="soulful-application" setBtnDisabled={setBtnDisabled} prefillApplicationForm={prefillApplicationForm} completeQuestionData={questionObject} validateData={validateData} formData={formData} setFormData={setFormData}/>
        )
        
        case "dropdown":  return (
          <div className={`single-group-child soulful-application ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
            <strong className='strongclass soulful-application' soulful-application><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span></strong>
            <div className='ui form soulful-application'>
              <div className='field'>
                  <CreatableSelect options={states} isDisabled={btnDisabled} placeholder="Select State" className='react-select soulful' styles={{width: '100%', height: '53px'}} value={{label: formData.state.value, value: formData.state.value, isFixed: true}} classNamePrefix='select' onChange={(item) => handleSelectState(item)}/>
              </div>
          </div> 
          {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
          </div>
        )
  
        case "simpleInputWithMap": return (
          <div className={`single-group-child soulful-application ${singleOrMulti === "single-element" ? singleOrMulti : ''} ${questionObject.dataSet['isError'] ? 'error-in-question' : ''}`}>
          <strong className='strongclass soulful-application'><Translate>{questionObject.dataSet.question}</Translate><span className='mandatory'>*</span>
          {!btnDisabled ? 
          <Badge role={'button'} className="float-end" color='warning' style={{color: 'black'}} onClick={() => handleAdderssByMap()}><MapPin className="primary" size={18} stroke="black"/><Translate>Locate on Map</Translate></Badge>
            // <span className="fs-4" role={'button'} onClick={() => handleAdderssByMap()}>{`(Click to Locate on Map)`}</span>
            : null
          }
          </strong> 
          <div className='ui form soulful-application'>
            <div className='field'>
            <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                <input type="text" style={{height: '53px'}} value={formData.address.value} disabled={btnDisabled} className="form-control soulful" name="address" id="address" placeholder="" onChange={(event) => setFormData({...formData, ['address']: {...formData['address'], 'value': event.target.value}})}/>
            </StandaloneSearchBox>
            </div>
        </div> 
        {questionObject['dataSet']['isError'] ? <p className='error-message-container'><Translate>{questionObject['dataSet']['errorMessage']}</Translate></p> : null}
        </div>
        )
  
        // case "radioWithOther":  return (
        //     <SimpleRadioWithOther className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        //     // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        // )
  
        case "checkboxWithOther":  return (
            <SimpleCheckboxWithOther formType="soulful-application" className={singleOrMulti} item={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        )
        
        // case "upload":  return (
        //     <Upload className={singleOrMulti} file={formData.resume.value} setLoader={setLoader} item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
        //     // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        // )
       
        case "dob":  return (
            <DOB className={singleOrMulti} formType="soulful-application" item={questionObject} email={formData.email.value} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        ) 
       
        case "radioWithExpander": return (
          <RadioHavingOptionChildren formType="soulful-application" className={singleOrMulti} item={questionObject['dataSet']} item1={questionObject} btnDisabled={btnDisabled} completeQuestionData={questionObject} validateData={validateData}/>
            // <Input item={questionObject} completeQuestionData={questionObject} validateData={validateData}/>
        )

        case "simpleRadioWithOther": return (
          <SimpleRadioWithOther formType="soulful-application" className={singleOrMulti} coursesList={[]} item={questionObject} completeQuestionData={questionObject} btnDisabled={btnDisabled} validateData={validateData}/>
        )
  
      }
    }

  }

  return (
    <div className='card soulful'>
      <div className='formbg soulful'>
        <ToastContainer/>
      <div id='viewDiv' style={{height: '10px', opacity: 0}}>

      </div>
      <SoulfulParagraph/>
      { certificationsList.length === 0 ?
      <div className='d-flex justify-content-evenly alignitems-center'>
        <Spinner/>
      </div> : <>
        <div className='single-elements-container soulful'>
            {formData.additionalCourse.dataSet.options.length ? 
              Object.keys(formData).map((eachQuestion, i) => {
              if (i <= 5) {
                  return (
                  <div>
                      {getContent(formData[eachQuestion], 'single-element')}
                  </div>
                )
              }
              }) : null
            }
        </div>
        <div className='soulful-question-container'>
            {formData.additionalCourse.dataSet.options.length ? 
            Object.keys(formData).map((eachQuestion, i) => {
            if (i > 5) {
                return (
                <div style={{paddingInline: i > 9 && i != 12 ? '8px' : '0px'}}>
                    {getContent(formData[eachQuestion])}
                </div>
                )
            }
            }) : null
            }
            <div className='single-submit-button' style={{paddingTop: '30px'}}>
              <FormButton text="Submit" color="dark" handleSubmit={() => handleSubmit()} loading={loader}/>
            </div>
        </div>
      </> 
      }
      </div>
      <Modal isOpen={show} onClosed={() => modalName === "Thank You" ? handleReloadForm() : handleCancel()} className="modal-dialog-centered">
          <ModalHeader>
            <Translate>
              {modalName !== "Thank You" && modalName !== "Verify Your Email" ? 'Mark Address' : modalName}
            </Translate>
            </ModalHeader>
          <ModalBody>
            {modalName === "Thank You" ? <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  <img src={FormSubmitted} style={{height: '100px', width: '100px'}}></img>
                  <p className='text-center fs-3'><Translate>Thank You!! Your response has been submitted</Translate>
                  {/* {formData.emailVerified && cancelClicked === false ? <Translate>{' and your email is verified successfully'}</Translate> : ''} */}
                  </p>
                  <div className='d-flex justify-content-end w-100 mt-4'>
                    <button style={{backgroundColor: `${Colors.primary} !important`}} className="button-primary" onClick={() => handleReloadForm()}><Translate>Okay</Translate></button>
                    <button className='mx-2 button-secondary' onClick={() => handleReloadForm()}><Translate>Cancel</Translate></button>
                  </div>
                  {/* {!formData.emailVerified ? 
                    <>
                      <strong className='text-center lh-1'>It seems that your email is not verified, Please enter the OTP that was sent to your email address.
                        </strong>

                        <OTPInput className="my-2" value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                        <div className='d-flex justify-content-end w-100 mt-4'>
                          <Button style={{backgroundColor: `${Colors.primary} !important`}} disabled={resendEmailBtnClicked} onClick={() => handleVerifyMyEmail()}>Verify Email</Button>
                          <Button className='mx-2' onClick={() => handleReloadForm()}>Cancel</Button>
                        </div>
                    </> : null
                  } */}
                </div>
            </div> : modalName === "Verify Your Email" ? <div>
                <div className='d-flex flex-column justify-evenly align-items-center'>
                  {/* <p className='text-center fs-3'>Thank You!! Your response has been submitted</p> */}
                  {!formData.emailVerified ? 
                    <>
                      {/* <strong className='text-center lh-1 my-2'><Translate>We have sent an email with an OTP and the verification link you can verify your email by either of the process.</Translate>
                        </strong> */}

                        {/* <OTPInput className="my-2" value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                        
                        <p className='text-center my-2'><Translate>OR</Translate></p> */}
                        <p className='text-justify fs-5'><Translate>Check your email for a verification link from Willdan Clean Energy Academy CMS: Email Verification. Make sure to click on the link received to complete your submission.</Translate></p>
                        
                        <div className='d-flex justify-content-end w-100 mt-4'>
                          <button style={{backgroundColor: `${Colors.primary} !important`}} className="button-primary" disabled={resendEmailBtnClicked} onClick={() => handleResendEmail()}><Translate>Re-send Email</Translate></button>
                          <button className='mx-2 button-secondary' onClick={() => handleReloadForm()}><Translate>Cancel</Translate></button>
                        </div>
                    </> : null
                  }
                </div>
            </div> : <GoogleMapAddress handleCancel={handleCancel} setAddressByMap={setAddressByMap}></GoogleMapAddress>
            }
          </ModalBody>
      </Modal>
    </div>
  )
}

export default SoulFulStudentApplication