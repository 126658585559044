import React, { useEffect, useState } from 'react'
import { Translate } from 'react-auto-translate'

const Step6 = ({start, end, formData, setFormData, component, validateData}) => {
    const [stepQuestions, setStepQuestions] = useState(null)

    useEffect(() => {
        setStepQuestions(formData)
    }, [formData])
    
    useEffect(() => {
        setTimeout(() => {
            validateData()
        }, 1000)
    }, [])

    // Bypass questions
    const bypassQuestions = ["lastJobPosition","workOrganization","workOrganizationSize","employedInEVsector","detailsCurrentRole","futurePlan","pastExperienceAreas","certifications","levelExperience","interest","evceExperience","pastEVCEProjects","evSupport","reasonEnroll","secondaryLanguage","improveUnderstandingTE","sourceApplication","referredBy"]
    
    if (!stepQuestions) {
        return <p>Loading....</p>
    }
    return (
        <div className='main-question-container'>
            <div style={{paddingInline: '70px', marginBlock: '20px'}}>
                <h4 style={{fontSize: '18px', fontWeight: '600'}}><Translate>Fill out the information below</Translate></h4>
            </div>
            <div className='inner-question-container no-bg'>
                {Object.keys(stepQuestions).map((eachQuestion, i) => {
                    console.log('stepQuestions[eachQuestion]', eachQuestion);
                    if (bypassQuestions.includes(eachQuestion)) {
                        return null
                    }
                    return <div key={'step6'+i}>
                        {component(stepQuestions[eachQuestion])}
                    </div>
                })}
            </div>
        </div>
    )
}

export default Step6