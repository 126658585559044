import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Translate } from 'react-auto-translate'
import './index.css'
import { GetCityByZipcode } from '../../../Apis/generalApis'
import { Badge } from 'reactstrap'

const SimpleRadio = (props) => {

    console.log("check1:---------------", props?.completeQuestionData?.dataSet?.formDataKey === "sex", props?.formData?.zipcode?.value)
    const [formSelectedLanguage, setFormSelectedLanguage] = useState('')
    const [editable, setEditable] = useState(false)
    const [hideCancel, setHideCancel] = useState(false)
    const [valuesLength, setValuesLength] = useState(null)
    const [disabled, setDisabled] = useState(props?.activeStep === 5 ? true : false)

    useEffect(() => {
      if (props?.completeQuestionData?.value) {
        setValuesLength((props?.completeQuestionData?.value).length)
      }
    }, [])
    
    useEffect(() => {
      if (valuesLength) {
        if ((valuesLength > (props?.completeQuestionData?.value).length) || (valuesLength < (props?.completeQuestionData?.value).length)) {
          setHideCancel(true)
        }
      }
    }, [props?.completeQuestionData?.value])
    
    const handleClickCancel = () => {
        setEditable(false)
        setHideCancel(false)
        setDisabled(true)
    }
  
    const handleChange = (event) => {
        if (props?.completeQuestionData.dataSet?.formDataKey === "sex" && (props?.formData?.zipcode?.value).length >= 4 && (props?.formData?.zipcode?.value).length <= 5) {
            // console.log("simple radio:-----------------------", props?.completeQuestionData.dataSet?.formDataKey, props?.completeQuestionData);
            GetCityByZipcode(props?.formData?.zipcode?.value).then(res => {
                if (res.data) {
                // console.log("city res:--------------------- ", res)
                const data = res.data
                if (data.length) {
                    const city = data[0]['attributes']['city']
                    const stateShortcode = data[0]['attributes']['state']
                    // console.log("city:--------------------------", city);
                    props.completeQuestionData.city = city
                    props.completeQuestionData.stateShortcode = stateShortcode
                }
                } else {
                props.completeQuestionData.city = ""
                }
            }).catch(error => {
                console.log("error while getting city from zipcode:", error);
            })
        }
        props.completeQuestionData['value'] = event.target.value
        props?.validateData()
    }

    let selectedLanguage = ''
    useEffect(() => {
        selectedLanguage = localStorage.getItem('newFormToLanguage') !== null ? localStorage.getItem('newFormToLanguage') : 'es'
        setFormSelectedLanguage(selectedLanguage)
    }, [])

    return (
        <div className={`single-group-child ${props?.formType} special-component ${props?.btnDisabled ? "disabled" : ''} ${props?.completeQuestionData?.isVisible == false ? 'hide-question' : null} ${props?.className} ${props?.item['isError'] ? 'error-in-question' : ''}`}>
            <strong className={`question-element ${props?.formType} special-component`}><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='required'>*</span> : null}{props?.activeStep === 5 ? <Badge className='mx-2 round' color={editable ? 'success' : 'warning'}  role='pill' style={{cursor: 'pointer', color: editable ? 'white' : 'black'}} onClick={() => {setEditable(!editable); setHideCancel(editable); setDisabled(editable)}}>{editable ? <Translate>Save</Translate> : <Translate>Edit</Translate>}</Badge>: null}{props?.activeStep === 5 && (editable && hideCancel === false)? <Badge className='mx-2 round' color={'secondary'}  role='pill' style={{cursor: 'pointer', color: 'white'}} onClick={() => handleClickCancel()}>{<Translate>Cancel</Translate>}</Badge>: null}</strong>
            
            {props?.formType === "adny" && props?.completeQuestionData?.addData ? <div>
            <p className='mt-2 fs-5'><Translate>All courses will be held on Zoom. Participants will need a Zoom Account and computer/tablet to receive educational materials.</Translate></p>
            </div> : null}
            
            <div className={`main-options-container ${props?.formType} ${props?.divisions}`}>
                {(props?.item?.options).map((option, i) => {
                    return (<div className={`check-option${editable ? '1' : ''} ${(props?.completeQuestionData?.isChildren ? props?.completeQuestionData?.value === `${option['line1']} ${option['line2']} ${option['line3']}` : props?.completeQuestionData?.value === option) ? 'show' : props?.activeStep === 5 ? 'hide' : 'show'}`}>
                    {props?.formType === "adny" && props?.completeQuestionData?.addData ? <div className={`check-option${editable ? '1' : ''} ${(props?.completeQuestionData?.isChildren ? props?.completeQuestionData?.value === `${option['line1']} ${option['line2']} ${option['line3']}` : props?.completeQuestionData?.value === option) ? 'show' : props?.activeStep === 5 ? 'hide' : 'show'} mt-2`}>
                        <p><strong className='text-decoration-underline strongclass mt-2 d-flex'><Translate> <input type="radio" id={(option['line1'])}
                            name={props?.item?.groupName}
                            className={`${props?.completeQuestionData?.isChildren ? 'mt-3' : ''}`}
                            style={{marginRight: '8px'}}
                            defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option} 
                            onChange={(event) => handleChange(event)}
                            checked={props?.completeQuestionData?.isChildren ? props?.completeQuestionData?.value === `${option['line1']} ${option['line2']} ${option['line3']}` : props?.completeQuestionData?.value === option}
                            disabled={props?.btnDisabled || disabled}
                            ></input>{props?.item?.courseAdditionalData[i]?.courseTitle}</Translate></strong></p>
                        {props?.item?.courseAdditionalData[i]?.courseTimeline && (props?.item?.courseAdditionalData[i]?.courseTimeline).map(timeline => {
                            return <p className='mb-0 fs-5'><Translate>{timeline}</Translate></p>
                        })}
                        </div> : null
                    }
                        <div className={`option-container check-option${editable ? '1' : ''} ${(props?.completeQuestionData?.isChildren ? props?.completeQuestionData?.value === `${option['line1']} ${option['line2']} ${option['line3']}` : props?.completeQuestionData?.value === option) ? 'show' : props?.activeStep === 5 ? 'hide' : 'show'} ${props?.completeQuestionData?.isChildren ? 'align-start' : ''}`} key={i} style={{marginBottom: props?.formType === "adny" && props?.completeQuestionData?.addData ? '20px' : ''}}>
                            {props?.formType === "adny" && props?.completeQuestionData?.addData ? null : <>
                            <input type="radio" id={(option['line1'])}
                            name={props?.item?.groupName}
                            className={`${props?.completeQuestionData?.isChildren ? 'mt-3' : ''}`}
                            defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option} 
                            onChange={(event) => handleChange(event)}
                            checked={props?.completeQuestionData?.isChildren ? props?.completeQuestionData?.value === `${option['line1']} ${option['line2']} ${option['line3']}` : props?.completeQuestionData?.value === option}
                            disabled={props?.btnDisabled || disabled}
                            >
                            </input>
                            {props?.completeQuestionData?.isChildren ? 
                            <div className='mb-2'>
                                <p className='m-0 mb-3 fs-5'><strong><Translate>{option['line1']}</Translate></strong></p>
                                <p className='m-0 mb-3 fs-5'><Translate>{option['line2']}</Translate></p>
                                <p className='m-0 mb-3 fs-5'><Translate>{option['line3']}</Translate></p>
                            </div> : <span className={`${props?.formType} option-label`}>{formSelectedLanguage === "en" ? option : <Translate>{option}</Translate>}</span>
                            }
                            </> 
                            }
                        </div>
                    </div>
                    )
                })}
            </div>
            {props?.item['isError'] ? <p className='error-message-container'><Translate>{props?.item['errorMessage']}</Translate></p> : null}
        </div>
    )
}

export default SimpleRadio

// We want one component for one question type. But in your case you make one one component for input, radio, checkbox and then using all these for making one question component