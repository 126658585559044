import React, { useEffect, useState } from 'react'

const Step1 = ({start, end, formData, setFormData, component}) => {
    const [stepQuestions, setStepQuestions] = useState(null)

    useEffect(() => {
        setStepQuestions(formData)
    }, [formData])
    
    if (!stepQuestions) {
        return <p>Loading....</p>
    }
    return (
        <div className='main-question-container'>
            <div className={`single-elements-container inner-question-container pt-4`} style={{gridTemplateColumns: '1fr 1fr'}}>
                {Object.keys(stepQuestions).map((eachQuestion, i) => {
                    if ((i + 1) >= start && (i + 1) <= end) {
                        return <div key={'step1'+i}>
                            {component(stepQuestions[eachQuestion])}
                        </div>
                    }
                })}
            </div>
        </div>
    )
}

export default Step1