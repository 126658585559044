import React, { useEffect, useState } from 'react'
import SubmittedImg from './../../assets/submitted_v2.png'
import { Translate } from 'react-auto-translate'
import ReactGA from 'react-ga4'
import ScriptInjector from './../../utils/scriptInjector'

const Submitted = ({start, end, formData, setFormData, component}) => {

    const [stepQuestions, setStepQuestions] = useState(null)

    useEffect(() => {
        setStepQuestions(formData)
    }, [formData])

    useEffect(() => {
        setTimeout(() => {
            window.open('https://soulfulsynergy.org')
        }, 30000)
    }, [])

    // Submit
    
    if (!stepQuestions) {
        return <p>Loading....</p>
    }
    return (
        <div className='main-question-container mt-5'>
            <ScriptInjector/>
            <div style={{paddingInline: '70px', marginBlock: '20px'}}>
                <h1 className='text-center' style={{fontSize: '25px', fontWeight: 900}}><Translate>Your Application has been submitted successfully!</Translate>
                    <br/><br/>
                    <Translate>You will receive a confirmation email with the next steps.</Translate>
                    <br/><br/>
                    <Translate>Good luck!</Translate>
                </h1>
            </div>
            <div className='d-flex justify-content-evenly align-items-center mt-5'>
                <img className='submitted-image-2' src={SubmittedImg} alt='form-submitted'></img>
            </div>
        </div>
    )
}

export default Submitted