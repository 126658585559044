import React from 'react'
import './../../../Footer.css'
import SoulfulLogo from '../../../soulful_logo.png'
import Life3Logo from '../../../assets/life3.png'

const SoulFulFooter = () => {
  return (
    <div className='footer soulful'>
      <div className='footer-image-outer-container'>
        <div className='footer-image-inner-container'>
          <img src={SoulfulLogo} alt="" height={160} width={115}/>
          <img className='pointer' src={Life3Logo} alt="life3" onClick={() => window.open('https://life3.io','_blank')} style={{cursor: 'pointer'}}/>
        </div>
      </div>
      <div className='footercontent soulful'>
        <p></p>
        <h5 className='text-font-sf'>For More information, Contact:</h5>
        <br />
        <br />
        <b className='text-white text-font-sf'>Dwayne R. Norris</b><br />
        <i className='text-white' text-font-sf>Co-Founder & COO <br/>Email: EVcharging@soulfulsynergy.org</i>
        <p className='copyright soulful text-font-sf'>Copyright  © 2023 Soulful Synergy - All Rights are Reserved</p>
      </div>
        <p className='w-100 mt-5 copyright soulful text-center float-center text-font-sf'><a className='text-white' href='https://life3.io' target='_blank' style={{textDecoration: 'none'}}>Powered by Learn & Innovate for Education, Enablement, Empowerment</a></p>
    </div>
  )
}

export default SoulFulFooter
// For class information, contact:

// Charles Calloway
// Director of Workforce Development
// charles@weact.org