import React, { useEffect, useState } from 'react'
import { Translate } from 'react-auto-translate';
import '../Checkbox.css'
import { Badge } from 'reactstrap';
const Checkbox = (props) => {

  // ** States
  const [editable, setEditable] = useState(false)
  const [hideCancel, setHideCancel] = useState(false)
  const [valuesLength, setValuesLength] = useState(null)
  const [disabled, setDisabled] = useState(props?.activeStep === 5 ? true : false)

  useEffect(() => {
    if (props?.completeQuestionData?.value) {
      setValuesLength((props?.completeQuestionData?.value).length)
    }
  }, [])
  
  useEffect(() => {
    if (valuesLength) {
      if ((valuesLength > (props?.completeQuestionData?.value).length) || (valuesLength < (props?.completeQuestionData?.value).length)) {
        setHideCancel(true)
      }
      console.log("value:------------------", valuesLength, (props?.completeQuestionData?.value).length, (valuesLength > (props?.completeQuestionData?.value).length) || (valuesLength < (props?.completeQuestionData?.value).length));
    }
  }, [props?.completeQuestionData?.value])

  const handleClickCancel = () => {
    setEditable(false)
    setHideCancel(false)
    setDisabled(true)
  }
  
  const handlechange=(e, option)=>{
    const {value ,checked } = e.target;
    let newarray = props?.completeQuestionData['value']
    if(checked){
      newarray.push(value)
      if (option === "None of the above" || option === "None of the above" || option === "I prefer not to say") {
        props.completeQuestionData['value'] = [option]
      } else {
        const valueArray = props.completeQuestionData['value']
        const filteredData = valueArray.filter((selectedOption) => (selectedOption !== "None of the above" && selectedOption !== "I prefer not to say"))
        props.completeQuestionData['value'] = filteredData
      }
    }
    else{
      props.completeQuestionData['value'] = newarray.filter((e) => e !== value)
      // props?.item?.setFormData({...props?.item?.formData, [props?.item?.formDataKey]:newarray.filter((e)=>e!==value)})
    }
    props?.validateData()
  }


  return (
    <div className={`single-group-child ${props?.formType} special-component ${props?.btnDisabled ? "disabled" : ''} ${props?.item['isError'] ? 'error-in-question' : ''}`}>
      {/* <strong className={`strongclass ${props?.formType} special-component`}><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='mandatory'>*</span> : null}{props?.activeStep === 5 ? <Badge className='mx-2 round' color={editable ? 'success' : 'warning'}  role='pill' style={{cursor: 'pointer', color: editable ? 'white' : 'black'}} onClick={() => {setEditable(!editable); setHideCancel(editable)}}>{editable ? <Translate>Save</Translate> : <Translate>Edit</Translate>}</Badge>: null}{props?.activeStep === 5 && (editable && hideCancel === false)? <Badge className='mx-2 round' color={'secondary'}  role='pill' style={{cursor: 'pointer', color: 'white'}} onClick={() => handleClickCancel()}>{<Translate>Cancel</Translate>}</Badge>: null}</strong> */}
      <strong className={`question-element ${props?.formType} special-component`}><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='required'>*</span> : null}{props?.activeStep === 5 ? <Badge className='mx-2 round' color={editable ? 'success' : 'warning'}  role='pill' style={{cursor: 'pointer', color: editable ? 'white' : 'black'}} onClick={() => {setEditable(!editable); setHideCancel(editable); setDisabled(editable)}}>{editable ? <Translate>Save</Translate> : <Translate>Edit</Translate>}</Badge>: null}{props?.activeStep === 5 && (editable && hideCancel === false)? <Badge className='mx-2 round' color={'secondary'}  role='pill' style={{cursor: 'pointer', color: 'white'}} onClick={() => handleClickCancel()}>{<Translate>Cancel</Translate>}</Badge>: null}</strong>
      <div className={`main-options-container ${props?.formType}`}>
        {(props?.item?.options).map((option, i) => {
              return (<div className={`check-option${editable ? '1' : ''} ${(props?.completeQuestionData?.value).includes(option) ? 'show' : props?.activeStep === 5 ? 'hide' : 'show'}`}>
                  {props?.formType === "adny" && props?.completeQuestionData?.addData ? <div className='mt-2'>
                  <p><strong className='text-decoration-underline strongclass mt-2'><Translate>  <input type="checkbox" id={(option)}
                    name={props?.item?.groupName}
                    style={{marginRight: '8px'}}
                    defaultValue={option} 
                    disabled={props?.btnDisabled || disabled}
                    checked={(props?.completeQuestionData?.value).includes(option)}
                    onChange={(event) => handlechange(event, option)}
                    >
                    </input>{props?.item?.courseAdditionalData[i]?.courseTitle}</Translate></strong></p>
                  {props?.item?.courseAdditionalData[i]?.courseTimeline && (props?.item?.courseAdditionalData[i]?.courseTimeline).map(timeline => {
                      return <p className='mb-0 fs-5'><Translate>{timeline}</Translate></p>
                  })}
                  </div> : null
                  }
                  <div className={`checkboxlabel check-option${editable ? '1' : ''} ${(props?.completeQuestionData?.value).includes(option) ? 'show' : props?.activeStep === 5 ? 'hide' : 'show'}`} key={i} style={{marginBottom: props?.formType === "adny" && props?.completeQuestionData?.addData ? '20px' : ''}}>
                      
                      {props?.formType === "adny" && props?.completeQuestionData?.addData ? null : <>
                      <input type="checkbox" id={(option)}
                      name={props?.item?.groupName}
                      defaultValue={option} 
                      disabled={props?.btnDisabled || disabled}
                      checked={(props?.completeQuestionData?.value).includes(option)}
                      onChange={(event) => handlechange(event, option)}
                      >
                      </input>
                      <span className={`${props?.formType} option-label`}><Translate>{option}</Translate></span>
                      </>}
                  </div>
              </div>
              )
        })}
      </div>
      {props?.item['isError'] ? <p className='error-message-container'><Translate>{props?.item['errorMessage']}</Translate></p> : null}
    </div>
  )
}

export default Checkbox
