import React, { useEffect, useState } from 'react'
import { Translate } from 'react-auto-translate'
import { Badge } from 'reactstrap'

const RadioHavingOptionChildren = (props) => {

    const [editable, setEditable] = useState(false)
    const [hideCancel, setHideCancel] = useState(false)
    const [valuesLength, setValuesLength] = useState(null)
    const [disabled, setDisabled] = useState(props?.activeStep === 5 ? true : false)
  
    useEffect(() => {
      if (props?.completeQuestionData?.value) {
        setValuesLength((props?.completeQuestionData?.value).length)
      }
    }, [])
    
    useEffect(() => {
      if (valuesLength) {
        if ((valuesLength > (props?.completeQuestionData?.value).length) || (valuesLength < (props?.completeQuestionData?.value).length)) {
          setHideCancel(true)
        }
        console.log("value:------------------", valuesLength, (props?.completeQuestionData?.value).length, (valuesLength > (props?.completeQuestionData?.value).length) || (valuesLength < (props?.completeQuestionData?.value).length));
      }
    }, [props?.completeQuestionData?.value])
  
    const handleClickCancel = () => {
      setEditable(false)
      setHideCancel(false)
      setDisabled(true)
    }

    const handleCheckValue = (event, selectedOption) => {
        if (event.target.checked) {
            let optionsList = props.completeQuestionData['dataSet']['options']
            optionsList.forEach((option) => {
                if (selectedOption.value === option.value) {
                    // If the selected radio option having only one children then set value
                    if ((option['childrens']).length === 1) {
                        props.completeQuestionData['value'] = option['childrens'][0]
                    } else if ((option['childrens']).length === 0) {
                        props.completeQuestionData['value'] = option['value']
                    } else {
                        props.completeQuestionData['value'] = ''
                    }
                    option.showChildren = true
                } else {
                    option.showChildren = false
                }
            })
            props.completeQuestionData['dataSet']['options'] = optionsList
        }
        props?.validateData()
    }

    const handleChange = (event, value) => {
        console.log("event.target.value: ", event.target.value, value);
        props.completeQuestionData['value'] = value
        props?.validateData()
    }

    const getQuestion = (question) => {
        if ((question).includes('Full Time')) {
            const splittedQuestion = (question).split('Full Time')
            return <p>{splittedQuestion[0]} <span style={{color: 'black', fontWeight: '600'}}><Translate>Full Time</Translate></span> <Translate>{splittedQuestion[1]}</Translate> {props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</p>
        } else if ((question).includes('Part Time')) {
            const splittedQuestion = (question).split('Part Time')
            return <p>{splittedQuestion[0]} <span style={{color: 'black', fontWeight: '600'}}><Translate>Part Time</Translate></span> <Translate>{splittedQuestion[1]}</Translate> {props?.item?.dataSet?.isRequired ? <span className='required'>*</span> : null}</p>    
        } else {
            return question
        }
    }

    return (
        <div className={`single-group-child ${props?.formType} special-component ${props?.btnDisabled ? "disabled" : ''} ${props?.item1?.isVisible !== undefined && !props?.item1?.isVisible ? 'hide-question' : null} ${props?.item['isError'] ? 'error-in-question' : ''}`}>
        <strong className={`question-element ${props?.formType} special-component`}><Translate>{props?.item?.question}</Translate>{props?.item?.isRequired ? <span className='required'>*</span> : null}{props?.activeStep === 5 ? <Badge className='mx-2 round' color={editable ? 'success' : 'warning'}  role='pill' style={{cursor: 'pointer', color: editable ? 'white' : 'black'}} onClick={() => {setEditable(!editable); setHideCancel(editable); setDisabled(editable)}}>{editable ? <Translate>Save</Translate> : <Translate>Edit</Translate>}</Badge>: null}{props?.activeStep === 5 && (editable && hideCancel === false)? <Badge className='mx-2 round' color={'secondary'}  role='pill' style={{cursor: 'pointer', color: 'white'}} onClick={() => handleClickCancel()}>{<Translate>Cancel</Translate>}</Badge>: null}</strong>
        {(props?.item?.options).map((option, i) => {
            return (
                <div className={`option-container d-flex check-option${editable ? '1' : ''} ${((option['showChildren']) || option.value === props?.completeQuestionData?.value) ? 'show' : props?.activeStep === 5 ? 'hide' : 'show'}`} key={i + props?.item?.groupName}>
                    <div>
                        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'start'}}>
                            <input type="radio" id={(option['line1'])}
                            name={props?.item?.groupName}
                            //  defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option} 
                            onChange={(event) => handleCheckValue(event, option)}
                            checked={(option['showChildren']) || option.value === props?.completeQuestionData?.value}
                            disabled={props?.btnDisabled || disabled}
                            >
                            </input>
                            <span className={`${props?.formType} option-label ${(option['showChildren']) ? 'text-bold' : null}`}><Translate>{option.value}</Translate></span>
                        </div>
                        <div>
                            {(option['showChildren']) && (option.childrens).map((childOption) => {  
                                return (
                                    <div className={`option-container mx-5 mb-2 check-option${editable ? '1' : ''} ${(props?.completeQuestionData?.value).includes(childOption) ? 'show' : props?.activeStep === 5 ? 'hide' : 'show'}`} key={i + childOption}>
                                        <input type="radio"
                                        name={childOption.groupName}
                                        // defaultValue={props?.completeQuestionData?.isChildren ? `${option['line1']} ${option['line2']} ${option['line3']}` : option} 
                                        onChange={(event) => handleChange(event, childOption)}
                                        checked={(props?.completeQuestionData?.value).includes(childOption)}
                                        disabled={props?.btnDisabled || disabled}
                                        >
                                        </input>
                                        <span className={`${props?.formType} option-label`}><Translate>{getQuestion(childOption)}</Translate></span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        })}

        {props?.item['isError'] ? <p className='error-message-container'><Translate>{props?.item['errorMessage']}</Translate></p> : null}
    </div>
    )
} 

export default RadioHavingOptionChildren